import axios from "axios";

import { getOneEntreprise } from "../actions/entreprises.actions";
import { getOneEnseigne } from "../actions/entreprises.actions";
import { getProduits } from "../actions/produits.actions";
import { getAllProduits } from "../actions/produits.actions";
import { getEnseignePreparations } from "../actions/preparations.actions";

export const GET_USER = "GET_USER";
export const UPLOAD_PICTURE = "UPLOAD_PICTURE";

export const getUser = (uid) => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/user/${uid}`)
      .then((res) => {
        dispatch({ type: GET_USER, payload: res.data });

        if (res.data.type === "Producteur") {
          dispatch(getOneEntreprise(res.data.idEntreprise));
          dispatch(getProduits(res.data.idEntreprise));
        } else if (res.data.type === "Restaurateur") {
          dispatch(getOneEnseigne(res.data.idEnseigne));
          dispatch(getEnseignePreparations(res.data.idEnseigne));
        } else {
          dispatch(getAllProduits());
        }
      })
      .catch((err) => console.log(err));
  };
};
