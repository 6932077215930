import { React, useState } from "react";
import NavBar from "../components/NavBar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProduits } from "../actions/produits.actions";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

// --------------------------------------- MUI ---------------------------------------
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";

const AjoutProduit = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // ------------------------------------ USER & CAT DATA -----------------------------------
  const userData = useSelector((state) => state.userReducer);
  const categoriesProductList = useSelector(
    (state) => state.categoriesProductsReducer
  );

  // ------------------------------------------ MP ------------------------------------------
  const [typeMPchoice, setTypeMPChoice] = useState("toutes");

  const listeMP = useSelector((state) => state.MPreducer);

  const [MP, setMP] = useState([]);
  const [tempMP, setTempMP] = useState();

  const ajouterMP = () => {
    if (tempMP && !MP.find((mp) => mp.NOM_MP === tempMP.NOM_MP)) {
      setMP([...MP, tempMP]);

      if (tempMP.ALLERGENE !== "") {
        listeAllergenes.forEach((allergene, i) => {
          if (allergene.nom === tempMP.ALLERGENE) {
            allergene.present = true;
            setListeAllergenes(listeAllergenes);
          }
        });
      }

      setTempMP(null);
    }
  };
  const supprimerMP = (indexDelete) => {
    const filterMP = MP.filter((tempMP, i) => i !== indexDelete);
    setMP(filterMP);

    listeAllergenes.forEach((allergene, i) => {
      if (
        filterMP.find((mp) => mp.ALLERGENE === allergene.nom) ||
        produits.find((p) => p.allergene.includes(allergene.nom))
      ) {
        allergene.present = true;
      } else {
        allergene.present = false;
      }
    });
    setListeAllergenes(listeAllergenes);
  };

  // ------------------------------------------ FICHE TECHNIQUE ------------------------------------------
  const listeProduits = useSelector((state) => state.allProductsReducer);

  const [produits, setProduits] = useState([]);
  const [tempProduit, setTempProduit] = useState();

  const ajouterProduit = () => {
    if (
      tempProduit &&
      !produits.find((p) => p.denomination === tempProduit.denomination)
    ) {
      setProduits([...produits, tempProduit]);

      if (tempProduit.allergene) {
        listeAllergenes.forEach((allergene, i) => {
          if (tempProduit.allergene.includes(allergene.nom)) {
            allergene.present = true;
            setListeAllergenes(listeAllergenes);
          }
        });
      }

      setTempProduit(null);
    }
  };
  const supprimerProduit = (indexDelete) => {
    const filterProduit = produits.filter(
      (tempProduit, i) => i !== indexDelete
    );
    setProduits(filterProduit);

    listeAllergenes.forEach((allergene, i) => {
      if (
        filterProduit.find((p) => p.allergene.includes(allergene.nom)) ||
        MP.find((mp) => mp.ALLERGENE === allergene.nom)
      ) {
        allergene.present = true;
      } else {
        allergene.present = false;
      }
    });
    setListeAllergenes(listeAllergenes);
  };

  // --------------------------------------- ADDITIFS ---------------------------------------
  const listeAdditifs = useSelector((state) => state.additifsReducer);

  const [additifs, setAdditifs] = useState([]);
  const [additifName, setAdditifName] = useState();

  const ajouterAdditif = () => {
    setAdditifs([...additifs, { code: additifName }]);
  };
  const supprimerAdditif = (indexDelete) => {
    setAdditifs((additifs) =>
      additifs.filter((additif, i) => i !== indexDelete)
    );
  };

  // --------------------------------- ALERGENES ---------------------------------
  const [listeAllergenes, setListeAllergenes] = useState([
    { nom: "Gluten", present: false },
    { nom: "Oeuf", present: false },
    { nom: "Lait", present: false },
    { nom: "Arachides", present: false },
    { nom: "Poissons", present: false },
    { nom: "Soja", present: false },
    { nom: "Fruits à coque", present: false },
    { nom: "Anhydride", present: false },
    { nom: "Crustacés", present: false },
    { nom: "Moutarde", present: false },
    { nom: "Mollusques", present: false },
    { nom: "Céleri", present: false },
    { nom: "Sésame", present: false },
  ]);

  // --------------------------------- DIALOGS ---------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openC, setOpenC] = useState(false);
  const handleClickOpenC = () => {
    setOpenC(true);
  };
  const handleCloseC = () => {
    setOpenC(false);
  };
  const [openF, setOpenF] = useState(false);
  const handleClickOpenF = () => {
    setOpenF(true);
  };
  const handleCloseF = () => {
    setOpenF(false);
  };

  // --------------------------------- IA ---------------------------------
  const [fileIA, setFileIA] = useState();

  const sendFileIA = async () => {
    if (fileIA) {
      const data = new FormData();
      data.append("file", fileIA);
      data.append("name", "pdf");
      data.append("id", "pdf");

      await axios
        .post(process.env.REACT_APP_SERVER_URL + `/api/user/uploadpdf`, data)
        .then((res) => {
          if (res.data.err) {
            alert("Votre fichier n'a pas pu être importé");
          } else {
            setTracesAllergenesPresents(res.data.product.traceAllergene);

            listeAllergenes.forEach((all) => {
              if (res.data.product.allergene.includes(all.nom)) {
                all.present = true;
              }
              setListeAllergenes(listeAllergenes);
            });

            alert("Les allergènes ont bien été importés");
          }
        })
        .catch(() => {});
    } else {
      alert("Veuillez selectionner un PDF");
    }
  };

  // ------------------------------- VARIABLES DONNEES -------------------------------
  const [denomination, setDenomination] = useState();
  const [code, setCode] = useState();
  const [marque, setMarque] = useState();
  const [description, setDescription] = useState();
  const [file, setFile] = useState();
  const [type, setType] = useState("Matière première");
  const [categorie, setCategorie] = useState();
  const [sousCategorie, setSousCategorie] = useState();
  const [pays, setPays] = useState();
  const [contenant, setContenant] = useState();
  const [KJ, setKJ] = useState();
  const [KC, setKC] = useState();
  const [matiereGrasse, setMatiereGrasse] = useState();
  const [acidesGrasSatures, setAcidesGrasSatures] = useState();
  const [acidesGrasPolyInsatures, setAcidesGrasPolyInsatures] = useState();
  const [acidesGrasMonoInsatures, setAcidesGrasMonoInsatures] = useState();
  const [acidesGrasTrans, setAcidesGrasTrans] = useState();
  const [glucides, setGlucides] = useState();
  const [sucres, setSucres] = useState();
  const [fibres, setFibres] = useState();
  const [proteines, setProteines] = useState();
  const [sel, setSel] = useState();
  const [conservation, setConservation] = useState();
  const [dateLimiteConsommation, setDateLimiteConsommation] = useState();
  const [dateLimiteConsommationAO, setDateLimiteConsommationAO] = useState();
  const [AEN, setAEN] = useState();
  const [poidsNetProduit, setPoidsNetProduit] = useState();
  const [poidsBrutProduit, setPoidsBrutProduit] = useState();
  const [longueurCarton, setLongueurCarton] = useState(0);
  const [largeurCarton, setLargeurCarton] = useState(0);
  const [hauteurCarton, setHauteurCarton] = useState(0);
  const [poidsNetLot, setPoidsNetLot] = useState(0);
  const [poidsCartonVide, setPoidsCartonVide] = useState(0);
  const [poidsBrutCarton, setPoidsBrutCarton] = useState(0);
  const [hauteurPaletteVide, setHauteurPaletteVide] = useState(0);
  const [nbPiecesParCarton, setNnbPiecesParCarton] = useState(0);
  const [nbCartonsParCouche, setNbCartonsParCouche] = useState(0);
  const [nbCouches, setNbCouches] = useState(0);
  const [germes, setGermes] = useState();
  const [criteres, setCriteres] = useState();
  const [unites, setUnites] = useState();
  const [commentaires, setCommentaires] = useState();
  const [nomRedaction, setNomRedaction] = useState();
  const [prenomRedaction, setPrenomRedaction] = useState();
  const [dateRedaction, setDateRedaction] = useState();
  const [nomValidation, setNomValidation] = useState();
  const [prenomValidation, setPrenomValidation] = useState();
  const [dateValidation, setDateValidation] = useState();

  // --------------------------------- ENVOI DONNEES ---------------------------------
  const handleProduct = async (state) => {
    const allIA = listeAllergenes.filter((a) => a.present).map((a) => a.nom);
    const allMP = MP.map((mp) => mp.ALLERGENE);
    let allProd = [];
    produits.map((p) => p.allergene.map((a) => allProd.push(a)));

    if (state === "Brouillon" || (state === "Validé" && denomination && code))
      await axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/product/create`,
        withCredentials: true,
        data: {
          denomination,
          code,
          marque,
          description,
          type,
          categorie,
          sousCategorie,
          pays,
          contenant,
          KJ,
          KC,
          matiereGrasse,
          acidesGrasSatures,
          acidesGrasPolyInsatures,
          acidesGrasMonoInsatures,
          acidesGrasTrans,
          glucides,
          sucres,
          fibres,
          proteines,
          sel,
          conservation,
          dateLimiteConsommation,
          dateLimiteConsommationAO,
          AEN,
          poidsNetProduit,
          poidsBrutProduit,
          longueurCarton,
          largeurCarton,
          hauteurCarton,
          poidsNetLot,
          poidsCartonVide,
          poidsBrutCarton,
          hauteurPaletteVide,
          nbPiecesParCarton,
          nbCartonsParCouche,
          nbCouches,
          germes,
          criteres,
          unites,
          commentaires,
          nomRedaction,
          prenomRedaction,
          dateRedaction,
          nomValidation,
          prenomValidation,
          dateValidation,
          additifs,
          idEntreprise: userData.idEntreprise,
          matierePremiere: MP.map((mp) => mp.NOM_MP),
          produits: produits.map((p) => p._id),
          allergene: allMP.concat(allProd).concat(allIA),
          traceAllergene: tracesAllergenesPresents,
          state,
        },
      })
        .then((res) => {
          if (res.data.errors) {
            alert(res.data.errors);
          } else {
            if (file) {
              const data = new FormData();
              data.append("file", file);
              data.append("name", res.data.produit._id);
              data.append("id", res.data.produit._id);
              axios
                .post(
                  process.env.REACT_APP_SERVER_URL +
                    `/api/product/upload-product`,
                  data
                )
                .then((res) => {
                  if (res.data.errors) {
                    alert(res.data.errors);
                  } else {
                    dispatch(getProduits(userData.idEntreprise));
                    alert("Votre produit a bien été ajouté");
                    history.push("/listeproduits");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              dispatch(getProduits(userData.idEntreprise));
              alert("Votre produit a bien été ajouté");
              history.push("/listeproduits");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    else
      alert(
        "Veuillez remplir la dénomination et le code avant de publier votre produit"
      );
  };

  // ------------------------------- MODIFIER LISTE TRACES ALLERGENES ------------------------------
  const [tracesAllergenesPresents, setTracesAllergenesPresents] = useState([]);

  const modifierTracesAllergene = (index) => {
    // ajout
    if (!tracesAllergenesPresents.includes(listeAllergenes[index].nom)) {
      setTracesAllergenesPresents([
        ...tracesAllergenesPresents,
        listeAllergenes[index].nom,
      ]);
    }
    // suppression
    else {
      setTracesAllergenesPresents(
        tracesAllergenesPresents.filter((a) => a !== listeAllergenes[index].nom)
      );
    }
  };

  return userData.type === "Producteur" ? (
    <div className="ajoutproduit">
      <div>
        <NavBar />
        <div className="inner">
          <h1>Ajout d'un nouveau produit</h1>

          <h3>Identification du produit</h3>

          <ul>
            <li key="" className="uploadIA">
              <label>
                Importez vos données depuis une fiche technique existante:{" "}
              </label>
              <input
                type="file"
                onChange={(e) => {
                  setFileIA(e.target.files[0]);
                }}
                accept="application/pdf,application/vnd.ms-excel"
              />
              <button onClick={sendFileIA}>Détecter les allergènes</button>
            </li>
          </ul>

          <div className="identif">
            <p>
              Remplissez les données du produit que vous souhaitez intégrer à
              votre liste. Nous nous occuperons de réviser vos données afin de
              vous aider à établir une fiche standard répondant précisement aux
              norme en vigueur.
            </p>
          </div>

          {/* ----------------------------------- IDENTIFICATION ----------------------------------- */}

          <div style={{ width: "95%" }}>
            <FormControl sx={{ marginLeft: "10px", marginTop: "30px" }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Votre produit est :
              </FormLabel>
              <RadioGroup className="radio" defaultValue="Matière première" row>
                <FormControlLabel
                  value="Matière première"
                  control={<Radio />}
                  label="Une matière première"
                  onChange={() => setType("Matière première")}
                />
                <FormControlLabel
                  value="Produit élaboré"
                  control={<Radio />}
                  label="Un produit élaboré"
                  onChange={() => setType("Produit élaboré")}
                />
              </RadioGroup>
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Dénomination*"
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setDenomination(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Code*"
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Marque"
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setMarque(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Pays d'origine"
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setPays(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Contenant"
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setContenant(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Image"
                  sx={{
                    margin: 1,
                    width: "85%",
                    paddingLeft: "15%",
                  }}
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  value={categorie}
                  options={[
                    ...new Set(
                      categoriesProductList.map((c) => c.alim_grp_nom_fr)
                    ),
                  ]}
                  onChange={(event, newValue) => {
                    setCategorie(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Catégorie"
                      sx={{ margin: 1 }}
                      component={Paper}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={sousCategorie}
                  options={categoriesProductList
                    .filter((c) => c.alim_grp_nom_fr === categorie)
                    .map((c) => c.alim_ssgrp_nom_fr)}
                  onChange={(event, newValue) => {
                    setSousCategorie(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sous catégorie"
                      sx={{ margin: 1 }}
                      component={Paper}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <TextField
              component={Paper}
              label="Description du produit"
              sx={{ margin: 1, width: "100%" }}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
            />

            {/* ----------------------------------------- TABLEAU INGREDIENTS ----------------------------------------- */}
            <TableContainer
              component={Paper}
              sx={{
                width: "50%",
                display: "block",
                margin: "auto",
                marginTop: 5,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Ingrédients
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Type
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Supprimer
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* -------------------------- MP -------------------------- */}
                  {MP.map((row, i) => (
                    <TableRow
                      key={row.NOM_MP}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.NOM_MP}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        Matière première
                      </TableCell>
                      <TableCell align="center">
                        <ion-icon
                          name="trash"
                          onClick={() => {
                            supprimerMP(i);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* -------------------------- Ingrédients pré élaborés -------------------------- */}
                  {produits.map((row, i) => (
                    <TableRow
                      key={row.denomination}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.denomination}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        Ingrédient pré élaboré
                      </TableCell>
                      <TableCell align="center">
                        <ion-icon
                          name="trash"
                          onClick={() => {
                            supprimerProduit(i);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* -------------------------- Additifs -------------------------- */}
                  {additifs.map((row, i) => (
                    <TableRow
                      key={row.code}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.code}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        Additif
                      </TableCell>
                      <TableCell align="center">
                        <ion-icon
                          name="trash"
                          onClick={() => {
                            supprimerAdditif(i);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={handleClickOpen}
                style={{ margin: "10px", border: 0 }}
              >
                + Ajouter une matière première
              </button>
              <button
                onClick={handleClickOpenF}
                style={{ margin: "10px", border: 0 }}
              >
                + Ajouter un ingrédient pré élaboré
              </button>
              <button
                onClick={handleClickOpenC}
                style={{ margin: "10px", border: 0 }}
              >
                + Ajouter un additif
              </button>
            </div>
          </div>

          {/* ----------------------------------------- DIALOG MP ----------------------------------------- */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Ajouter une matière première"}
            </DialogTitle>
            <DialogContent>
              <FormControl>
                <FormControl>
                  <RadioGroup
                    row
                    value={typeMPchoice}
                    defaultValue="toutes"
                    onChange={(e) => setTypeMPChoice(e.target.value)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ width: "100%" }}
                  >
                    <FormControlLabel
                      label="Aucun filtre"
                      control={<Radio />}
                      value="toutes"
                    />
                    <FormControlLabel
                      label="MP brutes"
                      control={<Radio />}
                      value="brute"
                    />
                    <FormControlLabel
                      label="MP élaborées"
                      control={<Radio />}
                      value="elabore"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                {listeMP && listeMP.length > 0 && (
                  <Autocomplete
                    id="disable-close-on-select"
                    value={tempMP}
                    options={
                      typeMPchoice === "toutes"
                        ? listeMP
                        : listeMP.filter((m) => m.type === typeMPchoice)
                    }
                    getOptionLabel={(option) => option.NOM_MP}
                    sx={{ width: "100%" }}
                    onChange={(event, newValue) => {
                      setTempMP(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Matières premières" />
                    )}
                  />
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  ajouterMP();
                }}
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>

          {/* ----------------------------------------- DIALOG FT ----------------------------------------- */}
          <Dialog open={openF} onClose={handleCloseF}>
            <DialogTitle>{"Ajouter un ingrédient existant"}</DialogTitle>
            <DialogContent>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                {listeProduits && listeProduits.length > 0 && (
                  <Autocomplete
                    options={listeProduits.filter((p) => p.state === "Validé")}
                    getOptionLabel={(option) =>
                      option.denomination + " - " + option.marque
                    }
                    value={tempProduit}
                    sx={{ width: 250 }}
                    onChange={(event, newValue) => {
                      setTempProduit(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ingrédient" />
                    )}
                  />
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseF();
                  ajouterProduit();
                }}
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>

          {/* ----------------------------------------- DIALOG ADDITIFS ----------------------------------------- */}
          <Dialog open={openC} onClose={handleCloseC}>
            <DialogTitle>{"Ajouter un additif"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormControl sx={{ m: 1 }}>
                  <Autocomplete
                    id="disable-close-on-select"
                    options={listeAdditifs}
                    getOptionLabel={(option) =>
                      option.code + " - " + option.nom
                    }
                    sx={{ width: 250 }}
                    onChange={(event, newValue) => {
                      setAdditifName(newValue.code);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Additifs" />
                    )}
                  />
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseC();
                  ajouterAdditif();
                }}
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>

          <ul>
            {/* ----------------------------------------- ALLERGENES ----------------------------------------- */}
            {/* ALLERGENES */}
            <li key="allergenes" className="allergenes">
              <FormGroup>
                <label>Allergènes :</label>
                {listeAllergenes.map((a) => (
                  <FormControlLabel
                    control={<Checkbox disabled checked={a.present} />}
                    label={a.nom}
                  />
                ))}
              </FormGroup>

              {/* TRACES D'ALLERGENES */}
              <FormGroup>
                <label>
                  Traces d'allergènes susceptibles d'être présents :
                </label>
                {listeAllergenes.map((a, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={tracesAllergenesPresents.includes(
                          a.nom
                        )}
                        checked={tracesAllergenesPresents.includes(a.nom)}
                        onChange={() => modifierTracesAllergene(i)}
                      />
                    }
                    label={a.nom}
                  />
                ))}
              </FormGroup>
            </li>
          </ul>

          {/* ----------------------------------------- VAL NUT ----------------------------------------- */}
          <h3>Valeurs nutritives</h3>
          <p className="imp">Taux exprimés pour 100g</p>
          <ul className="valNut1">
            <div>
              <li key="MG">
                <label>Matière grasse : </label>
                <input
                  type="number"
                  onChange={(e) => setMatiereGrasse(e.target.value)}
                />
              </li>
              <li key="AGT" className="sousChamp">
                <label>Acides gras trans : </label>
                <input
                  type="number"
                  onChange={(e) => setAcidesGrasTrans(e.target.value)}
                />
              </li>
              <li key="AGS" className="sousChamp">
                <label>Acides gras saturés : </label>
                <input
                  type="number"
                  onChange={(e) => setAcidesGrasSatures(e.target.value)}
                />
              </li>
              <li key="AGMI" className="sousChamp">
                <label>Acides gras mono insaturés : </label>
                <input
                  type="number"
                  onChange={(e) => setAcidesGrasMonoInsatures(e.target.value)}
                />
              </li>
              <li key="AGPI" className="sousChamp">
                <label>Acides gras poly insaturés : </label>
                <input
                  type="number"
                  onChange={(e) => setAcidesGrasPolyInsatures(e.target.value)}
                />
              </li>
              <li key="sel">
                <label>Sel : </label>
                <input type="number" onChange={(e) => setSel(e.target.value)} />
              </li>
            </div>

            <div>
              <li key="KJ">
                <label>Kilo Joules : </label>
                <input type="number" onChange={(e) => setKJ(e.target.value)} />
              </li>
              <li key="KC">
                <label>Kilo calories : </label>
                <input type="number" onChange={(e) => setKC(e.target.value)} />
              </li>
              <li key="GA">
                <label>Glucides assimilés : </label>
                <input
                  type="number"
                  onChange={(e) => setGlucides(e.target.value)}
                />
              </li>
              <li key="sucre" className="sousChamp">
                <label>Dont sucre : </label>
                <input
                  type="number"
                  onChange={(e) => setSucres(e.target.value)}
                />
              </li>
              <li key="FA">
                <label>Fibres alimentaires : </label>
                <input
                  type="number"
                  onChange={(e) => setFibres(e.target.value)}
                />
              </li>

              <li key="prot">
                <label>Protéines : </label>
                <input
                  type="number"
                  onChange={(e) => setProteines(e.target.value)}
                />
              </li>
            </div>
          </ul>

          <h3>Informations logistiques</h3>
          <ul className="infoLog">
            <li key="conditions" className="conditions">
              <label>Conditions de conservation et d'utilisation : </label>
              <input
                type="text"
                onChange={(e) => setConservation(e.target.value)}
              />
            </li>

            <li key="datlimcons">
              <label>Date limite de consommation : </label>
              <input
                type="date"
                onChange={(e) => setDateLimiteConsommation(e.target.value)}
              />
            </li>

            <li key="datlimconsAO">
              <label>Date limite de consommation après ouverture : </label>
              <input
                type="date"
                onChange={(e) => setDateLimiteConsommationAO(e.target.value)}
              />
            </li>

            <li key="AEN">
              <label>Code AEN : </label>
              <input type="text" onChange={(e) => setAEN(e.target.value)} />
            </li>
          </ul>

          <p className="impPoids">
            Toutes les valeurs doivent être exprimées en gramme
          </p>
          <ul className="poids">
            <li key="PNP">
              <label>Poids net produit (g) : </label>
              <input
                type="number"
                onChange={(e) => setPoidsNetProduit(e.target.value)}
              />
            </li>

            <li key="PBP">
              <label>Poids brut produit (g) : </label>
              <input
                type="number"
                onChange={(e) => setPoidsBrutProduit(e.target.value)}
              />
            </li>
          </ul>

          <h3>Dimensions du carton en cm</h3>
          <ul className="poids">
            <li key="long">
              <label>Longueur : </label>
              <input
                type="number"
                onChange={(e) => setLongueurCarton(e.target.value)}
              />
            </li>
            <li key="larg">
              <label>Largeur : </label>
              <input
                type="number"
                onChange={(e) => setLargeurCarton(e.target.value)}
              />
            </li>
            <li key="haut">
              <label>Hauteur : </label>
              <input
                type="number"
                onChange={(e) => setHauteurCarton(e.target.value)}
              />
            </li>
          </ul>

          <ul className="poids">
            <li key="PNL">
              <label>Poids net du lot : </label>
              <input
                type="number"
                onChange={(e) => setPoidsNetLot(e.target.value)}
              />
            </li>
            <li key="PCV">
              <label>Poids du carton vide : </label>
              <input
                type="number"
                onChange={(e) => setPoidsCartonVide(e.target.value)}
              />
            </li>
            <li key="PBC">
              <label>Poids brut du carton : </label>
              <input
                type="number"
                onChange={(e) => setPoidsBrutCarton(e.target.value)}
              />
            </li>
          </ul>

          <h3>Informations palettes</h3>

          <ul className="paletteVide">
            <li key="HPV">
              <label>Hauteur palette vide : </label>
              <input
                type="number"
                onChange={(e) => setHauteurPaletteVide(e.target.value)}
              />{" "}
              cm. (Palette ISO 13.1 cm, Palette EPAL 14.4 cm ...)
            </li>
          </ul>

          <ul className="valNut">
            <li key="NPC">
              <label>Nombre de pièces par carton : </label>
              <input
                type="number"
                onChange={(e) => setNnbPiecesParCarton(e.target.value)}
              />
            </li>
            <li key="hautpal">
              <label className="calcul">
                Hauteur de la palette :{" "}
                {parseInt(hauteurCarton) * parseInt(nbCouches) +
                  parseInt(hauteurPaletteVide)}{" "}
                cm.
              </label>
            </li>
            <li key="NCPC">
              <label>Nombre de cartons par couche : </label>
              <input
                type="number"
                onChange={(e) => setNbCartonsParCouche(e.target.value)}
              />
            </li>
            <li key="NCPP">
              <label className="calcul">
                Nombre de cartons par palette : {nbCartonsParCouche * nbCouches}{" "}
                cm.
              </label>
            </li>
            <li key="nbCouches">
              <label>Nombre de couches : </label>
              <input
                type="number"
                onChange={(e) => setNbCouches(e.target.value)}
              />
            </li>
          </ul>

          <h3>Informations microbiologiques</h3>
          <ul className="poidsMicrobio">
            <li key="germes">
              <label>Germes : </label>
              <input type="text" onChange={(e) => setGermes(e.target.value)} />
            </li>
            <li key="crit">
              <label>Critère : </label>
              <input
                type="text"
                onChange={(e) => setCriteres(e.target.value)}
              />
            </li>
            <li key="unite">
              <label>Unité : </label>
              <input type="text" onChange={(e) => setUnites(e.target.value)} />
            </li>
          </ul>

          <h3>Commentaires</h3>
          <textarea onChange={(e) => setCommentaires(e.target.value)} />

          <h3>Informations de contrôle</h3>
          <ul>
            <li key="redac" className="tableControle">
              <div>
                <label>Rédaction: </label>
                <br />
                <label>Validation: </label>
              </div>
              <div className="table">
                <div className="row">
                  <input
                    type="text"
                    placeholder="Nom"
                    onChange={(e) => setNomRedaction(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Prénom"
                    onChange={(e) => setPrenomRedaction(e.target.value)}
                  />
                  <input
                    type="date"
                    onChange={(e) => setDateRedaction(e.target.value)}
                  />
                </div>
                <div className="barre"></div>
                <div className="row">
                  <input
                    type="text"
                    placeholder="Nom"
                    onChange={(e) => setNomValidation(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Prénom"
                    onChange={(e) => setPrenomValidation(e.target.value)}
                  />
                  <input
                    type="date"
                    onChange={(e) => setDateValidation(e.target.value)}
                  />
                </div>
              </div>
            </li>
          </ul>

          <div className="actions">
            <button
              onClick={() => {
                handleProduct("Brouillon");
              }}
            >
              Enregister en brouillon
            </button>
            <button
              onClick={() => {
                handleProduct("Validé");
              }}
            >
              Envoyer et publier
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
      <br />
      <br />
      <br />
      Connectez-vous en tant que producteur pour accéder à cette page
      <br />
      <NavLink to="/">Retour</NavLink>
    </div>
  );
};

export default AjoutProduit;
