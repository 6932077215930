import axios from "axios";

export const GET_PRODUITS = "GET_PRODUITS";
export const GET_ALL_PRODUITS = "GET_ALL_PRODUITS";
export const DELETE_PRODUIT = "DELETE_PRODUIT";
export const GET_PRODUCT_ERRORS = "GET_PRODUCT_ERRORS";

export const getProduits = (id) => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/product/all/${id}`)
      .then((res) => {
        dispatch({ type: GET_PRODUITS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getAllProduits = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/product/all`)
      .then((res) => {
        dispatch({ type: GET_ALL_PRODUITS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const deletePost = (productId) => {
  return async (dispatch) => {
    return await axios({
      method: "delete",
      url: process.env.REACT_APP_SERVER_URL + `/api/product/delete/${productId}`,
    })
      .then((res) => {
        dispatch({ type: DELETE_PRODUIT, payload: { productId } });
      })
      .catch((err) => console.log(err));
  };
};

// export const addProduct = (data) => {
//   return async (dispatch) => {
//     return await axios
//       .post(.env.REACT_APP_SERVER_URL + `/api/product/`, data)
//       .then((res) => {
//         if (res.data.errors) {
//           dispatch({ type: GET_PRODUCT_ERRORS, payload: res.data.errors });
//         } else {
//           dispatch({ type: GET_PRODUCT_ERRORS, payload: "" });
//         }
//       });
//   };
// };
