import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import produitsReducer from "./produits.reducer";
import allProductsReducer from "./allProducts.reducer";
import entreprisesReducer from "./entreprises.reducer";
import MPreducer from "./MP.reducer";
import categoriesReducer from "./categories.reducer";
import preparationsReducer from "./preparations.reducer";
import additifsReducer from "./additifs.reducer";
import allEntreprisesReducer from "./allEntreprises.reducer";
import producteursReducer from "./producteurs.reducer";
import productNeedReducer from "./productNeed.reducer";
import typeRestReducer from "./typeRest.reducer";
import categoriesProductsReducer from "./categoriesProduct.reducer";
// import errorReducer from "./error.reducer";

export default combineReducers({
  userReducer,
  produitsReducer,
  allProductsReducer,
  entreprisesReducer,
  MPreducer,
  categoriesReducer,
  preparationsReducer,
  additifsReducer,
  allEntreprisesReducer,
  producteursReducer,
  productNeedReducer,
  typeRestReducer,
  categoriesProductsReducer,
  // errorReducer,
});
