import { React } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InscriptionProducteur from "../components/producteur/Inscription";
import InscriptionDistributeur from "../components/distributeur/Inscription";
import InscriptionRestaurateur from "../components/restaurateur/Inscription";

const Inscription = (props) => {
  let type = "r";
  if (props) {
    type = props.location.state;
  }

  return (
    <div className="inscription">
      <div className="ligne1">
        <Tooltip title="Connexion">
          <NavLink exact to="/">
            <ArrowBackIosIcon />
          </NavLink>
        </Tooltip>
        {type === "p" && <h1>Inscription en tant que producteur</h1>}
        {type === "d" && <h1>Inscription en tant que distributeur</h1>}
        {type === "r" && <h1>Inscription en tant que restaurateur</h1>}
      </div>
      {type === "p" && <InscriptionProducteur />}
      {type === "d" && <InscriptionDistributeur />}
      {type === "r" && <InscriptionRestaurateur />}
    </div>
  );
};

export default Inscription;
