import React from "react";
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import RamenDiningOutlinedIcon from "@mui/icons-material/RamenDiningOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const NavBar = () => {
  const userData = useSelector((state) => state.userReducer);

  return (
    <div className="navbar">
      <NavLink exact to="/accueil">
        <ArrowBackIosIcon className="back" />
      </NavLink>

      <div className="navIcons">
        {userData.type === "Producteur" && (
          <>
            <NavLink exact to="/ajoutproduit" activeClassName="nav-active">
              <AddCircleOutlineIcon className="icon" />
            </NavLink>
            <br />
            <NavLink exact to="/listeproduits" activeClassName="nav-active">
              <FormatListBulletedIcon className="icon" />
            </NavLink>
            <br />
          </>
        )}

        {userData.type === "Distributeur" && (
          <>
            <NavLink exact to="/listeproduits" activeClassName="nav-active">
              <FormatListBulletedIcon className="icon" />
            </NavLink>
            <br />
          </>
        )}

        {userData.type === "Restaurateur" && (
          <>
            <NavLink exact to="/ajoutpreparation" activeClassName="nav-active">
              <AddCircleOutlineIcon className="icon" />
            </NavLink>
            <br />
            <NavLink exact to="/listepreparations" activeClassName="nav-active">
              <FormatListBulletedIcon className="icon" />
            </NavLink>
            <br />
            <NavLink exact to="/carte" activeClassName="nav-active">
              <RamenDiningOutlinedIcon className="icon" />
            </NavLink>
            <br />
          </>
        )}

        <NavLink exact to="/compteperso" activeClassName="nav-active">
          <PersonOutlineIcon className="icon" />
        </NavLink>
      </div>
    </div>
  );
};

export default NavBar;
