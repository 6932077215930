import axios from "axios";

export const GET_ADDITIFS = "GET_ADDITIFS";

export const getAdditifs = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/additif/get/all`)
      .then((res) => {
        dispatch({ type: GET_ADDITIFS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
