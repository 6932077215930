import { React } from "react";
import NavBar from "../components/NavBar";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import CircularProgress from "@mui/material/CircularProgress";
import { NavLink } from "react-router-dom";

const Carte = () => {
  // ------------------------------- REDUCERS ------------------------------
  const userData = useSelector((state) => state.userReducer);
  const entreprise = useSelector((state) => state.entreprisesReducer);
  const preparations = useSelector((state) => state.preparationsReducer);

  // ------------------------------ PDF ------------------------------
  const pdfDownload = (e) => {
    e.preventDefault();
    let doc = new jsPDF("p", "pt", "A4");
    doc.html(document.getElementById("pdf-view-carte"), {
      callback: () => {
        doc.save("carte.pdf");
      },
      x: 10,
      y: 10,
    });
  };

  const loadAllergenes = (preparation) => {
    let allergenes = [];

    preparation.ingredients &&
      preparation.ingredients.length > 0 &&
      preparation.ingredients.forEach((ing) => {
        if (ing.type === "Matière première") allergenes.push(ing.allergenes);
        else ing.allergenes.map((all) => allergenes.push(all));
      });

    var uniqueAll = [...new Set(allergenes)];
    return uniqueAll.filter((n) => n).join(", ");
  };

  return userData.type === "Restaurateur" ? (
    <div className="carte">
      <NavBar />
      <div className="inner">
        <div className="row1">
          <h1>Votre carte</h1>
          <div className="icon" onClick={pdfDownload}>
            Télécharger la carte <ion-icon name="download-outline" />
          </div>
        </div>
        <div id="carte">
          {preparations &&
            preparations.length > 0 &&
            preparations.map(
              (p, i) =>
                p.carte && (
                  <ul>
                    <li>
                      <h2>{p.nom}</h2>
                      <br />
                      <div>{p.categorie}</div>
                      <br />
                      <label>Allergènes : </label>
                      <>{loadAllergenes(p)}</>
                      <br />
                      <div className="barre"></div>
                    </li>
                  </ul>
                )
            )}
        </div>
        <div style={{ display: "none" }}>
          <div id="pdf-view-carte">
            <h3>Carte - {entreprise.nomEnseigne}</h3>
            {preparations &&
              preparations.length > 0 &&
              preparations.map(
                (p, i) =>
                  p.carte && (
                    <ul>
                      <li>
                        <h2>{p.nom}</h2>
                        <br />
                        <div>{p.categorie}</div>
                        <br />
                        <label>Allergènes : </label>
                        <>{loadAllergenes(p)}</>
                        <br />
                        <div className="barre"></div>
                      </li>
                    </ul>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
      <br />
      <br />
      <br />
      Connectez-vous en tant que restaurateur pour accéder à cette page
      <br />
      <NavLink to="/">Retour</NavLink>
    </div>
  );
};

export default Carte;
