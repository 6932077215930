import { React, useContext } from "react";
import Logo2 from "../images/logo2.png";
import { NavLink } from "react-router-dom";
import { UidContext } from "../components/AppContext";
import DashBoard from "./DashBoard";

const Accueil = () => {
  const uid = useContext(UidContext);

  return uid ? (
    <DashBoard />
  ) : (
    <div className="accueil">
      <div className="accueil2" id="#accueil2">
        <img src={Logo2} alt="" />
        <div className="inner">
          <div className="vous">Vous êtes ...</div>
          <NavLink
            to={{
              pathname: "/accueil",
              state: "p",
            }}
          >
            <button>
              <div>
                <ion-icon name="leaf"></ion-icon>
              </div>
              Producteur
            </button>
          </NavLink>
          <NavLink
            to={{
              pathname: "/accueil",
              state: "d",
            }}
          >
            <button>
              <div>
                <ion-icon name="cart"></ion-icon>
              </div>
              Distributeur
            </button>
          </NavLink>
          <NavLink
            to={{
              pathname: "/accueil",
              state: "r",
            }}
          >
            <button>
              <div>
                <ion-icon name="restaurant"></ion-icon>
              </div>
              Restaurateur
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Accueil;
