import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const params = useParams();
  const id = params.id;
  const token = params.token;

  const [password, setPassword] = useState();
  const [Rpassword, setRPassword] = useState();

  const handleChangeMDP = () => {
    if (password && password === Rpassword) {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/user/resetpassword/${token}/${id}`,
        withCredentials: true,
        data: {
          password,
        },
      })
        .then((res) => {
          if (res.data.err) {
            alert(res.data.err);
          } else {
            alert("Votre mot de passe a bien été mis à jour");
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert(
        "Les mots de passe doivent être identitques et doivent contenir un caractère spécial, une majuscule et un chiffre. Huit caractères minimum."
      );
    }
  };

  return (
    <div className="forgotPassword">
      <div className="inner">
        <div className="ligne1">
          <Tooltip title="Accueil">
            <NavLink exact to="/accueil">
              <ArrowBackIosIcon
                sx={{
                  "& > :not(style)": {
                    color: "rgb(13, 19, 46)",
                  },
                }}
              />
            </NavLink>
          </Tooltip>
          <span>Changement de mot de passe</span>
        </div>
        <div className="data">
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            label="Mot de passe"
            type="password"
            variant="standard"
            className="field"
            sx={{
              "& > :not(style)": {
                color: "rgb(13, 19, 46)",
              },
            }}
          />
          <br />
          <TextField
            onChange={(e) => setRPassword(e.target.value)}
            label="Répétez le mot de passe"
            type="password"
            variant="standard"
            className="field"
            sx={{
              "& > :not(style)": {
                color: "rgb(13, 19, 46)",
              },
            }}
          />
          <br />
          <button onClick={handleChangeMDP}>Changer le mot de passe</button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
