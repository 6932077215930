import React from "react";
import NavBar from "../components/NavBar";
import { useSelector } from "react-redux";

const ComptePerso = () => {
  // --------------------------------- GET DATA ---------------------------------
  const userData = useSelector((state) => state.userReducer);
  const entreprise = useSelector((state) => state.entreprisesReducer);

  return (
    <div className="compteperso">
      <NavBar />
      <div className="inner">
        <h1>Compte personnel</h1>

        <div className="data">
          <ul>
            <li>
              <b>Informations personelles</b>
            </li>
            <br />
            <li>
              <label>Identifiant : </label>
              {userData.identifiant}
            </li>
            <li>
              <label>Nom : </label>
              {userData.nom}
            </li>
            <li>
              <label>Prénom : </label>
              {userData.prenom}
            </li>
            <li>
              <label>Téléphone : </label>
              {userData.telephone}
            </li>
            <li>
              <label>Mail : </label>
              {userData.email}
            </li>
            <li>
              <label>Connexion en tant que : </label>
              {userData.type}
            </li>
          </ul>

          <ul>
            {userData.type === "Producteur" && (
              <>
                <li>
                  <b>Informations professionelles</b>
                </li>
                <br />
                <li>
                  <label>Nom de la société : </label>
                  {entreprise.nomSociete}
                </li>
                <li>
                  <label>N° de siret : </label>
                  {entreprise.siret}
                </li>
                <li>
                  <label>N° TVA intercommunautaire : </label>
                  {entreprise.numTva}
                </li>
                <li>
                  <label>Adresse : </label>
                  {entreprise.adresse}
                </li>
                <li>
                  <label>Code postal: </label>
                  {entreprise.cdp}
                </li>
                <li>
                  <label>Ville : </label>
                  {entreprise.ville}
                </li>
                <li>
                  <label>Région : </label>
                  {entreprise.region}
                </li>
                <li>
                  <label>Pays : </label>
                  {entreprise.pays}
                </li>
                <li>
                  <label>Site web : </label>
                  {entreprise.web}
                </li>
              </>
            )}

            {userData.type === "Restaurateur" && (
              <>
                <li>
                  <b>Informations de l'établissement</b>
                </li>
                <br />
                <li>
                  <label>Nom de l'enseigne : </label>
                  {entreprise.nomEnseigne}
                </li>
                <li>
                  <label>Adresse : </label>
                  {entreprise.adresse}
                </li>
                <li>
                  <label>Code postal: </label>
                  {entreprise.cdp}
                </li>
                <li>
                  <label>Ville : </label>
                  {entreprise.ville}
                </li>
                <li>
                  <label>Région : </label>
                  {entreprise.region}
                </li>
                <li>
                  <label>Pays : </label>
                  {entreprise.pays}
                </li>
                <li>
                  <label>Téléphone du restaurant : </label>
                  {entreprise.telephone}
                </li>
                <li>
                  <label>Mail du restaurant : </label>
                  {entreprise.email}
                </li>
                <li>
                  <label>Site web : </label>
                  {entreprise.web}
                </li>
                <li>
                  <label>Catégorie de commerce alimentaire : </label>
                  {entreprise.typeRest}
                </li>
                <li>
                  <label>Destination : </label>
                  {entreprise.destination}
                </li>
                <li>
                  <label>Emplacement : </label>
                  {entreprise.emplacement}
                </li>
                <li>
                  <label>Restauration livrée : </label>
                  {entreprise.livraison}
                  <br />
                  <br />
                  <br />
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComptePerso;
