import { React, useState } from "react";
import NavBar from "../components/NavBar";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { getEnseignePreparations } from "../actions/preparations.actions";
import { useDispatch } from "react-redux";

// MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

const ListePreparations = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // ---------------------------------- GET USER & PREPARATIONS DATA----------------------------------
  const preparations = useSelector((state) => state.preparationsReducer);
  const allProducts = useSelector((state) => state.allProductsReducer);
  const userData = useSelector((state) => state.userReducer);

  // -------------------------------------- DIALOG --------------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ------------------------------ TOOLBAR ------------------------------
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  // ------------------------------ INDICE AFFICHAGE DIALOG ------------------------------
  const [indexClic, setIndexClic] = useState(0);

  // ------------------------------ SAVE CARTE ------------------------------
  const changeBoolCarte = (index) => {
    if (document.getElementById("cb" + index).checked) {
      preparations[index].carte = true;
    } else {
      preparations[index].carte = false;
    }
  };

  const saveCarte = async () => {
    await preparations.forEach((preparation, i) => {
      axios({
        method: "patch",
        url:
          process.env.REACT_APP_SERVER_URL +
          `/api/prepa/add/carte/${preparation._id}`,
        data: { carteBool: preparation.carte },
      })
        .then((res) => {
          if (!res.data.err && i === preparations.length - 1) {
            alert("La carte a bien été mise à jour");
          }
        })
        .catch((err) => console.log(err));
    });
  };

  // ------------------------------ DATAGRID ------------------------------
  const [showMasque, setShowMasque] = useState(false);

  const loadRows = (masque) => {
    let rows = [];
    for (let x in preparations) {
      if (masque === preparations[x].masque) {
        let rowItem = {};
        rowItem.id = preparations[x]._id;
        rowItem.nom = preparations[x].nom;
        rowItem.categorie = preparations[x].categorie;
        rowItem.i = x;
        rows.push(rowItem);
      }
    }

    return rows;
  };

  const columns = [
    { field: "nom", headerName: "Préparation", width: 300 },
    { field: "categorie", headerName: "Catégorie", width: 300 },
    {
      field: "apercu",
      headerName: "Aperçu",
      width: 100,
      renderCell: (params) => {
        const onClick = () => {
          handleClickOpen();
          setIndexClic(params.row.i);
        };
        return (
          <ion-icon
            name="eye"
            onClick={onClick}
            style={{ cursor: "pointer" }}
          ></ion-icon>
        );
      },
    },
    {
      field: "modifier",
      headerName: "Modifier",
      width: 100,
      renderCell: (params) => {
        const onClick = () => {
          history.push("/modifierpreparation", {
            preparation: preparations[params.row.i],
          });
        };
        return (
          <ion-icon
            name="create"
            onClick={onClick}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      field: "carte",
      headerName: "À la carte",
      width: 100,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            id={"cb" + params.row.i}
            onChange={(e) => changeBoolCarte(params.row.i)}
            defaultChecked={preparations[params.row.i].carte}
          />
        );
      },
    },
    {
      field: "hide",
      headerName: "Affichage",
      width: 100,
      renderCell: (params) => {
        const onClick = (masque) => {
          axios({
            method: "patch",
            url:
              process.env.REACT_APP_SERVER_URL +
              `/api/prepa/masque/prepa/${preparations[params.row.i]._id}`,
            withCredentials: true,
            data: {
              masque,
            },
          })
            .then((res) => {
              if (res.data.err) {
                alert(res.data.err);
              } else {
                dispatch(getEnseignePreparations(userData.idEnseigne));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        };
        return showMasque ? (
          <div
            className="maskOptions"
            onClick={() => {
              onClick(false);
            }}
            style={{ cursor: "pointer" }}
          >
            Démasquer
          </div>
        ) : (
          <div
            className="maskOptions"
            onClick={() => {
              onClick(true);
            }}
            style={{ cursor: "pointer" }}
          >
            Masquer
          </div>
        );
      },
    },
  ];

  // Load les allergènes de chaque produit & MP
  const loadAllergenes = (ingredient) => {
    if (ingredient.type === "Produit") {
      var uniqueAll = [
        ...new Set(allProducts.find((p) => p._id === ingredient.id).allergene),
      ];
      return uniqueAll.filter((n) => n).length === 0
        ? ""
        : uniqueAll.filter((n) => n).join(", ");
    } else return ingredient.allergenes;
  };

  return userData.type === "Restaurateur" ? (
    <div className="listeProduits">
      <NavBar />
      <div className="inner">
        <div className="ligne1">
          <h1>Liste de vos préparations</h1>
          <div>
            <button onClick={saveCarte}>
              <ion-icon name="star" /> Sauvegarder la carte
            </button>
            {showMasque ? (
              <button
                className="masque"
                onClick={() => {
                  setShowMasque(false);
                }}
              >
                <ion-icon name="apps" /> Afficher les préparations non masqués
              </button>
            ) : (
              <button
                className="masque"
                onClick={() => {
                  setShowMasque(true);
                }}
              >
                <ion-icon name="apps" /> Afficher les préparations masqués
              </button>
            )}
          </div>
        </div>

        {/* ------------------------------ DATAGRID ------------------------------ */}
        <div
          style={{
            height: "68vh",
            width: "100%",
            backgroundColor: showMasque
              ? "rgba(47, 57, 78, 0.207)"
              : "transparent",
            borderRadius: "6px",
          }}
        >
          <DataGrid
            {...{ preparations }}
            columns={columns}
            rows={loadRows(showMasque)}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>

        <div className="add">
          <NavLink exact to="/ajoutpreparation">
            <button>AJOUTER UNE PREPARATION</button>
          </NavLink>
        </div>
      </div>

      {/* -------------------------------------- DIALOG -------------------------------------- */}
      {preparations && preparations.length > 0 && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <b>{preparations[indexClic].nom}</b>
          </DialogTitle>
          <DialogContent className="productDialog">
            <DialogContentText id="alert-dialog-description">
              <div className="picFlex">
                {preparations[indexClic].photo !==
                  "./upload/preparation/random-prepa.png" && (
                  <img
                    alt="prepa"
                    src={preparations[indexClic].photo}
                    style={{
                      width: "30%",
                      float: "right",
                      borderRadius: "6px",
                    }}
                  />
                )}
                <div>
                  <b>Catégories : </b>
                  {preparations[indexClic].categorie}
                  <br />
                  <b>Poids de la préparation : </b>
                  {preparations[indexClic].poids}g
                  <br />
                  <b>Coût estimé de la préparation : </b>
                  {preparations[indexClic].cout}€
                  <br />
                  <b>Nombre de parts estimé : </b>
                  {preparations[indexClic].parts}
                  <br />
                  <b>Etapes de préparation : </b>
                  {preparations[indexClic].commentaires}
                  <br />
                </div>
              </div>

              {/* ----------------------------------- TABLEAU DIALOG ----------------------------------- */}
              <TableContainer sx={{ marginTop: "20px" }} component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ padding: "7px", fontWeight: "bold" }}
                        align="center"
                        className="head"
                      >
                        Ingrédients
                      </TableCell>
                      <TableCell
                        sx={{ padding: "7px", fontWeight: "bold" }}
                        align="center"
                        className="head"
                      >
                        Quantité
                      </TableCell>
                      <TableCell
                        sx={{ padding: "7px", fontWeight: "bold" }}
                        align="center"
                        className="head"
                      >
                        Type
                      </TableCell>
                      <TableCell
                        sx={{ padding: "7px", fontWeight: "bold" }}
                        align="center"
                        className="head"
                      >
                        Allergènes
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preparations[indexClic].ingredients &&
                      preparations[indexClic].ingredients.length > 0 &&
                      preparations[indexClic].ingredients.map(
                        (ingredient, i) => (
                          <TableRow key={i}>
                            <TableCell sx={{ padding: "5px" }} align="center">
                              {ingredient.type === "Produit"
                                ? allProducts.find(
                                    (p) => p._id === ingredient.id
                                  ).denomination
                                : ingredient.nom}
                            </TableCell>
                            <TableCell sx={{ padding: "5px" }} align="center">
                              {ingredient.quantity}g
                            </TableCell>
                            <TableCell sx={{ padding: "5px" }} align="center">
                              {ingredient.type}
                            </TableCell>
                            <TableCell sx={{ padding: "5px" }} align="center">
                              {loadAllergenes(ingredient)}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
      <br />
      <br />
      <br />
      Connectez-vous en tant que restaurateur pour accéder à cette page
      <br />
      <NavLink to="/">Retour</NavLink>
    </div>
  );
};

export default ListePreparations;
