import React from "react";
import { jsPDF } from "jspdf";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";

const PDF = ({ produit }) => {
  const entreprises = useSelector((state) => state.allEntreprisesReducer);
  const allProducts = useSelector((state) => state.allProductsReducer);

  const pdfDownload = (e) => {
    e.preventDefault();
    let doc = new jsPDF("p", "mm", [630, 891]);
    doc.html(document.getElementById("pdf-view"), {
      callback: () => {
        doc.save(produit.denomination + ".pdf");
      },
      x: 10,
      y: 10,
    });
  };

  const loadAllergenes = () => {
    let allergenes = [];

    produit.allergene.forEach((all) => {
      allergenes.push(all);
    });

    var uniqueAll = [...new Set(allergenes)];
    return uniqueAll.filter((n) => n).length === 0
      ? "Aucun"
      : uniqueAll.filter((n) => n).join(", ");
  };

  const loadTracesAllergenes = () => {
    let allergenes = [];

    produit.traceAllergene.forEach((all) => {
      allergenes.push(all);
    });

    var uniqueAll = [...new Set(allergenes)];
    return uniqueAll.filter((n) => n).length === 0
      ? "Aucun"
      : uniqueAll.filter((n) => n).join(", ");
  };

  const loadIngredients = () => {
    let ingrédients = [];

    produit.matierePremiere.forEach((matierePremiere) => {
      ingrédients.push(matierePremiere);
    });

    produit.produits.forEach((pId) => {
      if (allProducts.find((pFromList) => pFromList._id === pId).denomination)
        ingrédients.push(
          allProducts.find((pFromList) => pFromList._id === pId).denomination
        );
      else alert("Un des ingrédient constituant votre produit a été supprimé");
    });

    produit.additifs.forEach((add) => {
      ingrédients.push(add.code);
    });

    var uniqueIng = [...new Set(ingrédients)];
    return uniqueIng.filter((n) => n).length === 0
      ? "Aucun"
      : uniqueIng.filter((n) => n).join(" / ");
  };

  return (
    <>
      <Tooltip title="Télécharger">
        <DownloadIcon
          sx={{
            cursor: "pointer",
            fontSize: 20,
          }}
          onClick={pdfDownload}
        />
      </Tooltip>
      <div className="pdf" id="pdf-view">
        <h2>
          {produit.denomination} - {produit.marque}
        </h2>
        <div className="picFlex">
          {produit.photo !== "./upload/product/random-product.png" && (
            <img src={produit.photo} alt="product" />
          )}
          <div>
            <h3>Identification du produit</h3>
            <label>Code :</label> {produit.code}
            <br />
            <label>Description :</label> {produit.description}
            <br />
            <label>Type :</label> {produit.type}
            <br />
            <label>Catégorie :</label> {produit.categorie}
            <br />
            <label>Sous-catégorie :</label> {produit.sousCategorie}
            <br />
            <label>Pays d'origine :</label> {produit.pays}
            <br />
            <label>Contenant :</label> {produit.contenant}
            <br />
            <label>Ingrédients :</label> <>{loadIngredients()}</>
            <br />
            <label>Allergenes :</label> <>{loadAllergenes()}</>
            <br />
            <label>Traces d'allergenes :</label> <>{loadTracesAllergenes()}</>
            <br />
          </div>
        </div>
        <h3>Valeurs nutritives</h3>
        <span className="valNutListe">
          <ul>
            <li>
              <label>KiloJoules :</label> {produit.KJ}
            </li>
            <li>
              <label>KiloCalories :</label> {produit.KC}
            </li>
            <li>
              <label>Matière grasse :</label> {produit.matiereGrasse}
              <li>
                <label>- Acides gras trans :</label> {produit.acidesGrasTrans}
              </li>
              <li>
                <label>- Acides gras saturés :</label>{" "}
                {produit.acidesGrasSatures}
              </li>
              <li>
                <label>- Acides gras mono insaturés :</label>{" "}
                {produit.acidesGrasMonoInsatures}
              </li>
              <li>
                <label>- Acides gras poly insaturés :</label>{" "}
                {produit.acidesGrasPolyInsatures}
              </li>
            </li>
            <li>
              <label>Glucides assimilés :</label> {produit.glucides}
              <li>
                <label>- Dont sucre :</label> {produit.sucres}
              </li>
            </li>
            <li>
              <label>Fibres alimentaires :</label> {produit.fibres}
            </li>
            <li>
              <label>Protéines :</label> {produit.proteines}
            </li>
            <li>
              <label>Sel :</label> {produit.sel}
            </li>
          </ul>
        </span>
        <br />
        <h3>Informations logistiques</h3>
        <label>Conditions de conservation et d'utilisation :</label>{" "}
        {produit.type}
        <br />
        <label>Date limite de conservation :</label>{" "}
        {produit.dateLimiteConsommation}
        <br />
        <label>Date limite de conservation après ouverture :</label>{" "}
        {produit.dateLimiteConsommationAO}
        <br />
        <label>Code AEN :</label>
        {produit.AEN}
        <br />
        <label>Poids net du produit :</label> {produit.poidsNetProduit}g.
        <br />
        <label>Poids brut du produit :</label> {produit.poidsBrutProduit}g.
        <br />
        <h3>Dimensions du carton</h3>
        <label>Longueur :</label> {produit.longueurCarton} cm.
        <br />
        <label>Largeur :</label> {produit.largeurCarton} cm.
        <br />
        <label>Hauteur :</label> {produit.hauteurCarton} cm.
        <br />
        <label>Poids net du lot :</label> {produit.poidsNetLot} g.
        <br />
        <label>Poids du carton vide :</label> {produit.poidsCartonVide} g.
        <br />
        <label>Poids brut du carton :</label> {produit.poidsBrutCarton} g.
        <br />
        <h3>Informations palettes</h3>
        <label>Hauteur de la palette vide :</label> {produit.hauteurPaletteVide}{" "}
        cm.
        <br />
        <label>Nombre de pièces par carton :</label> {produit.nbPiecesParCarton}
        <br />
        <label>Nombre de cartons par couche :</label>{" "}
        {produit.nbCartonsParCouche}
        <br />
        <label>Nombre de couches :</label> {produit.nbCouches}
        <br />
        <label>Nombre de cartons par palette :</label> {produit.type}
        <br />
        <label>Hauteur de la palette :</label> {produit.type}
        <br />
        <h3>Informations microbiologiques</h3>
        <label>Germes :</label> {produit.germes}
        <br />
        <label>Critère :</label> {produit.criteres}
        <br />
        <label>Unité :</label> {produit.unites}
        <br />
        <h3>Informations de contrôle</h3>
        <label>Commentaires :</label> {produit.commentaires}
        <br />
        <label>Fiche crée par :</label> {produit.prenomRedaction}{" "}
        {produit.nomRedaction} le {produit.dateRedaction}
        <br />
        <label>Fiche révisée par :</label> {produit.prenomValidation}{" "}
        {produit.nomValidation} le {produit.dateValidation}
        <br />
        <label>Producteur :</label>{" "}
        {entreprises &&
          entreprises.length > 0 &&
          entreprises.find((e) => e._id === produit.idEntreprise).nomSociete}
        <br />
      </div>
    </>
  );
};

export default PDF;
