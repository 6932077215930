import { React, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { UidContext } from "../components/AppContext";
import DashBoard from "./DashBoard";
import Accueil from "./Accueil";
import CircularProgress from "@mui/material/CircularProgress";

const Connection = (props) => {
  let type = props.location.state;

  const [load, setLoad] = useState(false);

  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(false);

  const uid = useContext(UidContext);

  const handleLogin = () => {
    console.log("env", process.env.REACT_APP_SERVER_URL);
    setLoad(true);
    if (type === "p") {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/user/login/prod`,
        withCredentials: true,
        data: {
          email: identifiant,
          password: password,
        },
      })
        .then((res) => {
          if (res.data.err) {
            setError(true);
          } else {
            window.location = "/accueil";
          }
          setLoad(false);
        })
        .catch((err) => {
          alert(err);
          setLoad(false);
        });
    } else if (type === "d") {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/user/login/dist`,
        withCredentials: true,
        data: {
          email: identifiant,
          password: password,
        },
      })
        .then((res) => {
          if (res.data.err) {
            setError(true);
          } else {
            window.location = "/accueil";
          }
          setLoad(false);
        })
        .catch((err) => {
          setError(true);
          setLoad(false);
        });
    } else {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/user/login/rest`,
        withCredentials: true,
        data: {
          email: identifiant,
          password: password,
        },
      })
        .then((res) => {
          if (res.data.err) {
            setError(true);
          } else {
            window.location = "/accueil";
          }
          setLoad(false);
        })
        .catch((err) => {
          setError(true);
          setLoad(false);
        });
    }
  };

  return uid ? (
    <DashBoard />
  ) : type ? (
    <>
      <div className="connection">
        <div className="textHaut">
          <div className="ligne1">
            <span>
              <Tooltip title="Accueil">
                <NavLink exact to="/">
                  <ArrowBackIosIcon
                    sx={{
                      "& > :not(style)": {
                        color: "rgb(13, 19, 46)",
                      },
                    }}
                  />
                </NavLink>
              </Tooltip>
              <span>Connexion</span>
            </span>
          </div>
          {type === "p" && (
            <h3>
              Pour accéder à votre page producteur veuillez indiquer votre
              identifiant et votre mot de passe.
            </h3>
          )}
          {type === "d" && (
            <h3>
              Pour accéder à votre page distributeur veuillez indiquer votre
              identifiant et votre mot de passe.
            </h3>
          )}
          {type === "r" && (
            <h3>
              Pour accéder à votre page restaurateur veuillez indiquer votre
              identifiant et votre mot de passe.
            </h3>
          )}
          <h5>
            Si vous avez des difficultés à vous connecter,{" "}
            <NavLink exact to="/forgotpassword">
              cliquez ici.
            </NavLink>
          </h5>
        </div>
        <div className="data">
          <TextField
            onChange={(e) => setIdentifiant(e.target.value)}
            label="Email"
            variant="standard"
            className="field"
            sx={{
              "& > :not(style)": {
                color: "rgb(13, 19, 46)",
              },
            }}
            error={error ? true : false}
          />
          <br />
          <TextField
            helperText={error ? "Mot de passe incorrect" : ""}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            label="Mot de passe"
            variant="standard"
            className="field"
            sx={{
              "& > :not(style)": {
                color: "rgb(13, 19, 46)",
              },
            }}
            error={error ? true : false}
          />
          <br />
          {load ? (
            <CircularProgress />
          ) : (
            <button onClick={handleLogin}>Se connecter</button>
          )}
        </div>
        <div className="pasInscrit">
          <h3>
            Vous n'êtes pas encore inscrit ?
            <NavLink
              to={{
                pathname: "/inscription",
                state: type,
              }}
            >
              Inscription
            </NavLink>
          </h3>
        </div>
      </div>
    </>
  ) : (
    <Accueil />
  );
};

export default Connection;
