import axios from "axios";

export const GET_ALL_ENTREPRISES = "GET_ALL_ENTREPRISES";

export const getAllEntreprises = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/entreprise/get/all`)
      .then((res) => {
        dispatch({ type: GET_ALL_ENTREPRISES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
