import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";

const AddMissingProduct = ({ props, handleCloseC }) => {
  const userData = useSelector((state) => state.userReducer);
  const producteurs = useSelector((state) => state.producteursReducer);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [producteur, setProducteur] = useState();
  const [idProducteur, setIdProducteur] = useState();
  const [producteurNonInscrit, setProducteurNonInscrit] = useState();
  const [produit, setProduit] = useState();

  const sendDemande = async () => {
    if ((producteur || producteurNonInscrit) && produit) {
      let finalProd = "";
      let inscrit = false;

      if (producteurNonInscrit) {
        finalProd = producteurNonInscrit;
        inscrit = false;
      } else {
        finalProd = producteur;
        inscrit = true;
      }

      await axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/productneed/create`,
        withCredentials: true,
        data: {
          produit,
          idRestaurateur: userData._id,
          nomRestaurateur: userData.prenom + " " + userData.nom,
          idProducteur: idProducteur,
          nomProducteur: finalProd,
          producteurInscrit: inscrit,
          etat: "Demande en cours",
          nomPrepa: props,
        },
      })
        .then((res) => {
          if (res.data.err) {
            alert("Echec de la demande.");
          } else {
            alert("Votre demande a bien été envoyée.");
            handleCloseC();
          }
        })
        .catch((err) => {
          alert("laSS");
          console.log(err);
        });
    } else {
      alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  return (
    <div className="accordion">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            Vous ne trouvez pas votre ingrédient ? Envoyez une demande d'ajout à
            un producteur.
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {producteurs && producteurs.length > 0 && (
            <Autocomplete
              id="disable-close-on-select"
              options={producteurs}
              getOptionLabel={(option) => option.prenom + " " + option.nom}
              sx={{ width: "100%", marginBottom: 2 }}
              onChange={(event, newValue) => {
                setProducteur(newValue.prenom + " " + newValue.nom);
                setIdProducteur(newValue._id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Nom du producteur" />
              )}
            />
          )}

          <Typography sx={{ color: "text.secondary", fontSize: "13px" }}>
            Le producteur n'est pas encore inscrit ? Entrez son nom ci-dessous :
          </Typography>
          <TextField
            sx={{ width: "100%", marginTop: 1 }}
            label="(Optionnel)"
            onChange={(e) => setProducteurNonInscrit(e.target.value)}
          />

          <TextField
            sx={{ width: "100%", marginTop: 2 }}
            label="Produit à ajouter"
            onChange={(e) => setProduit(e.target.value)}
          />

          <button onClick={sendDemande}>Envoyer une demande d'ajout</button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AddMissingProduct;
