import { GET_PRODUITS } from "../actions/produits.actions";
import { GET_ALL_PRODUITS } from "../actions/produits.actions";
import { DELETE_PRODUIT } from "../actions/produits.actions";

const initialState = {};

export default function produitsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUITS:
      return action.payload;
    case GET_ALL_PRODUITS:
      return action.payload;
    case DELETE_PRODUIT:
      return state.filter(
        (product) => product._id !== action.payload.productId
      );
    default:
      return state;
  }
}
