import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Logout from "../components/Logout";
import { useSelector } from "react-redux";
import RamenDiningOutlinedIcon from "@mui/icons-material/RamenDiningOutlined";
import Badge from "@mui/material/Badge";
import axios from "axios";
import { getAllPN } from "../actions/productNeed.actions";
import { useDispatch } from "react-redux";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

const DashBoard = () => {
  const dispatch = useDispatch();

  // ------------------------------------- GET DATA --------------------------------------
  const userData = useSelector((state) => state.userReducer);
  const entreprise = useSelector((state) => state.entreprisesReducer);
  const productNeed = useSelector((state) => state.productNeedReducer);

  // ------------------------------------------- DIALOGS --------------------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = async () => {
    setOpen2(false);

    await axios({
      method: "patch",
      url: process.env.REACT_APP_SERVER_URL + `/api/productneed/notif/${userData._id}`,
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.err) {
          alert(res.data.err);
        } else {
          dispatch(getAllPN());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ------------------------------------------- Change PN State --------------------------------------------
  const changePNState = async (indexPN) => {
    await axios({
      method: "patch",
      url: process.env.REACT_APP_SERVER_URL + `/api/productneed/etat/${productNeed[indexPN]._id}`,
      withCredentials: true,
      data: {
        etat: "Ajouté",
      },
    })
      .then((res) => {
        if (res.data.err) {
          alert(res.data.err);
        } else {
          alert("Vos changements ont bien été pris en compte");
          dispatch(getAllPN());
        }
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  };

  return userData ? (
    <div className="dashboard">
      <div className="ligne1">
        <div className="left" />
        <h1>
          {userData.prenom} {userData.nom} - {userData.type}
        </h1>
        <div className="right">
          <Logout />
        </div>
      </div>
      <div className="inner">
        {userData.type === "Producteur" && (
          <>
            <div>
              <AddCircleOutlineIcon className="icon" />
              <NavLink exact to="/ajoutproduit">
                Ajouter un produit
              </NavLink>
            </div>
            <div>
              <FormatListBulletedIcon className="icon" />
              <NavLink exact to="/listeproduits">
                Liste des produits
              </NavLink>
            </div>
            <div onClick={handleClickOpen}>
              <NotificationsNoneIcon className="icon" />
              Demandes d'ajout de produit
            </div>
          </>
        )}

        {userData.type === "Distributeur" && (
          <>
            <div>
              <FormatListBulletedIcon className="icon" />
              <NavLink exact to="/listeproduits">
                Liste des produits
              </NavLink>
            </div>
          </>
        )}

        {userData.type === "Restaurateur" && (
          <>
            <div>
              <AddCircleOutlineIcon className="icon" />
              <NavLink exact to="/ajoutpreparation">
                Ajouter une préparation
              </NavLink>
            </div>

            <div>
              <FormatListBulletedIcon className="icon" />
              <NavLink exact to="/listepreparations">
                Liste des préparations
              </NavLink>
            </div>

            <div>
              <RamenDiningOutlinedIcon className="icon" />
              <NavLink exact to="/carte">
                Voir la carte
              </NavLink>
            </div>

            <div onClick={handleClickOpen2}>
              {productNeed.find(
                (pn) => pn.idRestaurateur === userData._id && pn.notif === true
              ) ? (
                <Badge variant="dot" color="primary" className="icon">
                  <NotificationsNoneIcon />
                </Badge>
              ) : (
                <NotificationsNoneIcon className="icon" />
              )}
              Mes demandes d'ajout de produit
            </div>
          </>
        )}

        <div>
          <PersonOutlineIcon className="icon" />
          <NavLink exact to="/compteperso">
            Compte personnel
          </NavLink>
        </div>
      </div>
      {userData.type === "Producteur" && (
        <div className="coord">
          <div>{entreprise.nomSociete}</div>
          <div>{entreprise.adresse}</div>
          <div>
            {entreprise.cdp}, {entreprise.region}
          </div>
          <div>
            {entreprise.ville}, {entreprise.pays}
          </div>
        </div>
      )}
      {userData.type === "Restaurateur" && (
        <div className="coord">
          <div>{entreprise.nomEnseigne}</div>
          <div>{entreprise.adresse}</div>
          <div>{entreprise.cdp}</div>
          <div>
            {entreprise.ville}, {entreprise.pays}
          </div>
        </div>
      )}

      {/* ------------------------------------- Producteurs -------------------------------------*/}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Demandes d'ajout de produit"}</DialogTitle>
        <DialogContent>
          <h5>
            Vous trouverez ci-dessous vos demandes d'ajout de produits par des
            restaurateurs. Une fois le produit ajouté, appuyez sur "Le produit a
            bien été ajouté" afin que le restaurateur puisse l'utiliser.
          </h5>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "fit-content" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b>Produit demandé</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Restaurateur</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Validation</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productNeed &&
                  productNeed.length > 0 &&
                  productNeed.map(
                    (row, i) =>
                      row.idProducteur === userData._id &&
                      row.etat === "Demande en cours" && (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{row.produit}</TableCell>
                          <TableCell align="center">
                            {row.nomRestaurateur}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              onClick={() => {
                                changePNState(i);
                              }}
                              style={{
                                cursor: "pointer",
                                color: "rgba(31, 72, 185, 0.76)",
                              }}
                            >
                              Confirmer l'ajout du produit
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          </TableContainer>

          {userData.admin && (
            <>
              <br />
              <h5>
                Vous trouverez ci-dessous les demandes d'ajout de produits par
                des restaurateurs. Veuillez contacter le producteur afin qu'il
                s'inscrive sur la plateforme et ajoute le produit. Une fois le
                produit ajouté, cliquez sur "Le produit a bien été ajouté" afin
                que le restaurateur puisse l'utiliser.
              </h5>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: "fit-content" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <b>Produit demandé</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Restaurateur</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Producteur à contacter</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Validation</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productNeed &&
                      productNeed.length > 0 &&
                      productNeed.map(
                        (row, i) =>
                          !row.producteurInscrit &&
                          row.etat === "Demande en cours" && (
                            <TableRow
                              key="enCours"
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {row.produit}
                              </TableCell>
                              <TableCell align="center">
                                {row.nomRestaurateur}
                              </TableCell>
                              <TableCell align="center">
                                {row.nomProducteur}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  onClick={() => {
                                    changePNState(i);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "rgba(31, 72, 185, 0.76)",
                                  }}
                                >
                                  Confirmer l'ajout du produit
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>

      {/* ------------------------------------- Restaurateurs -------------------------------------*/}
      <Dialog open={open2} onClose={handleClose2}>
        <DialogTitle>{"Mes demandes d'ajout de produit"}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "fit-content" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <b>Produit demandé</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Producteur</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Préparation</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Etat</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productNeed &&
                  productNeed.length > 0 &&
                  productNeed.map(
                    (row, i) =>
                      row.idRestaurateur === userData._id && (
                        <TableRow
                          key="map"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{row.produit}</TableCell>
                          <TableCell align="center">
                            {row.nomProducteur}
                          </TableCell>
                          <TableCell align="center">{row.nomPrepa}</TableCell>
                          <TableCell align="center">
                            {row.notif ? (
                              <Badge
                                variant="dot"
                                color="primary"
                                className="icon"
                              >
                                {row.etat}
                              </Badge>
                            ) : (
                              <>{row.etat}</>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
    </div>
  );
};

export default DashBoard;
