import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";

// redux
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// actions
import { getAllMP } from "./actions/MP.actions";
import { getCategories } from "./actions/categories.actions";
import { getAllProducts } from "./actions/allProducts.actions";
import { getAdditifs } from "./actions/additifs.actions";
import { getAllEntreprises } from "./actions/allEntreprises.actions";
import { getAllProducteurs } from "./actions/producteurs.actions";
import { getAllPN } from "./actions/productNeed.actions";
import { getTypeRest } from "./actions/typeRest.actions";
import { getCategoriesProduct } from "./actions/categoriesProducts.actions";

const store = createStore(rootReducer, applyMiddleware(thunk));

store.dispatch(getAllMP());
store.dispatch(getAllProducts());
store.dispatch(getCategories());
store.dispatch(getAdditifs());
store.dispatch(getAllEntreprises());
store.dispatch(getAllProducteurs());
store.dispatch(getAllPN());
store.dispatch(getTypeRest());
store.dispatch(getCategoriesProduct());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
