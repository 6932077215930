import { GET_ALL_PRODUITS } from "../actions/allProducts.actions";

const initialState = {};

export default function allProductsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRODUITS:
      return action.payload;
    default:
      return state;
  }
}
