import React, { useState } from "react";
import NavBar from "../components/NavBar";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getProduits } from "../actions/produits.actions";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import PDF from "../components/PDF";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

const ListeProduits = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // ---------------------------------- GET DATA----------------------------------
  const produits = useSelector((state) => state.produitsReducer);
  const allProduits = useSelector((state) => state.allProductsReducer);
  const userData = useSelector((state) => state.userReducer);
  const entreprises = useSelector((state) => state.allEntreprisesReducer);
  const listeMP = useSelector((state) => state.MPreducer);

  // -------------------------------------- DIALOG --------------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ------------------------------ INDICE AFFICHAGE DIALOG ------------------------------
  const [indexClic, setIndexClic] = useState(0);

  // ------------------------------ DATAGRID ------------------------------
  const [showMasque, setShowMasque] = useState(false);

  const loadRows = (masque) => {
    let rows = [];
    for (let x in produits) {
      if (masque === produits[x].masque) {
        let rowItem = {};
        rowItem.id = produits[x]._id;
        rowItem.code = produits[x].code;
        rowItem.denomination = produits[x].denomination;
        rowItem.marque = produits[x].marque;
        rowItem.type = produits[x].type;
        rowItem.state = produits[x].state;
        rowItem.entreprise = entreprises.find(
          (e) => e._id === produits[x].idEntreprise
        ).nomSociete;
        rowItem.i = x;
        rows.push(rowItem);
      }
    }

    return rows;
  };

  const columns = [
    { field: "code", headerName: "Code", width: 100 },
    { field: "denomination", headerName: "Produit", width: 150 },
    {
      field: "entreprise",
      headerName: "Entreprise de production",
      width: 150,
    },
    {
      field: "marque",
      headerName: "Marque",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "apercu",
      headerName: "Aperçu",
      width: 80,
      renderCell: (params) => {
        return (
          <ion-icon
            name="eye"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClickOpen();
              setIndexClic(params.row.i);
            }}
          ></ion-icon>
        );
      },
    },
  ];

  userData.type === "Producteur" &&
    columns.push(
      {
        field: "modifier",
        headerName: "Modifier",
        width: 80,
        renderCell: (params) => {
          const onClick = () => {
            const mp = listeMP.filter((mpFromList) =>
              produits[params.row.i].matierePremiere.includes(mpFromList.NOM_MP)
            );

            const prodFound = allProduits.filter((p) =>
              produits[params.row.i].produits.includes(p._id)
            );

            // -------------------------------- LOAD AI DATA --------------------------------
            let allProdTemp = [];
            prodFound.map((p) => p.allergene.map((a) => allProdTemp.push(a)));
            let allIA = [];
            produits[params.row.i].allergene.map(
              (a) =>
                !mp.map((mp) => mp.ALLERGENE).includes(a) &&
                !allProdTemp.includes(a) &&
                allIA.push(a)
            );

            history.push("/modifierproduit", {
              produit: produits[params.row.i],
              mp: mp,
              prodFound: prodFound,
              allIA: allIA,
            });
          };
          return (
            <ion-icon
              name="create"
              style={{ cursor: "pointer" }}
              onClick={onClick}
            />
          );
        },
      },
      {
        field: "state",
        headerName: "Etat",
        width: 80,
      },
      {
        field: "hide",
        headerName: "Affichage",
        width: 80,
        renderCell: (params) => {
          const onClick = (masque) => {
            axios({
              method: "patch",
              url:
                process.env.REACT_APP_SERVER_URL +
                `/api/product/masque/product/${produits[params.row.i]._id}`,
              withCredentials: true,
              data: {
                masque,
              },
            })
              .then((res) => {
                if (res.data.err) {
                  alert(res.data.err);
                } else {
                  dispatch(getProduits(userData.idEntreprise));
                }
              })
              .catch((err) => {
                console.log(err);
              });
          };
          return showMasque ? (
            <div
              className="maskOptions"
              style={{ cursor: "pointer", color: "rgb(252, 80, 80)" }}
              onClick={() => {
                onClick(false);
              }}
            >
              Démasquer
            </div>
          ) : (
            <div
              className="maskOptions"
              style={{ cursor: "pointer", color: "rgb(252, 80, 80)" }}
              onClick={() => {
                onClick(true);
              }}
            >
              Masquer
            </div>
          );
        },
      }
    );

  // ------------------------------ TOOLBAR ------------------------------
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  return userData.type === "Producteur" || userData.type === "Distributeur" ? (
    <div className="listeProduits">
      <NavBar />
      <div className="inner">
        <div className="row1">
          {userData.type === "Producteur" && <h1>Liste de vos produits</h1>}
          {userData.type === "Distributeur" && <h1>Liste des produits</h1>}
          {showMasque ? (
            <div
              className="masque"
              onClick={() => {
                setShowMasque(false);
              }}
            >
              Afficher les produits non masqués
            </div>
          ) : (
            userData.type === "Producteur" && (
              <div
                className="masque"
                onClick={() => {
                  setShowMasque(true);
                }}
              >
                Afficher les produits masqués
              </div>
            )
          )}
        </div>

        <div
          style={{
            height: 380,
            width: "100%",
            backgroundColor: showMasque
              ? "rgba(47, 57, 78, 0.207)"
              : "transparent",
            borderRadius: "6px",
          }}
        >
          <DataGrid
            {...{ produits }}
            columns={columns}
            rows={loadRows(showMasque)}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>

        {userData.type === "Producteur" && (
          <div className="add">
            <NavLink exact to="/ajoutproduit">
              <button>AJOUTER UN PRODUIT</button>
            </NavLink>
          </div>
        )}
      </div>

      {produits && produits.length > 0 && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <PDF produit={produits[indexClic]} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
      <br />
      <br />
      <br />
      Connectez-vous en tant que producteur ou distributeur pour accéder à cette
      <br />
      <NavLink to="/">Retour</NavLink>
    </div>
  );
};

export default ListeProduits;
