import React from "react";
import axios from "axios";
import cookie from "js-cookie";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";

const Logout = () => {
  const removeCookie = (key) => {
    if (window !== "undefined") {
      cookie.remove(key, { expires: 1 });
    }
  };

  const logout = async () => {
    await axios({
      method: "get",
      url: process.env.REACT_APP_SERVER_URL + `/api/user/logout`,
      withCredentials: true,
    })
      .then(() => removeCookie("jwt"))
      .catch((err) => console.log(err));
    window.location = "/";
  };

  return (
    <Tooltip title="Se déconnecter">
      <LogoutIcon onClick={logout} className="logout" />
    </Tooltip>
  );
};

export default Logout;
