import axios from "axios";

export const GET_ALL_PRODUITS = "GET_ALL_PRODUITS";

export const getAllProducts = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/product/all`)
      .then((res) => {
        dispatch({ type: GET_ALL_PRODUITS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
