import { React, useState } from "react";
import NavBar from "../components/NavBar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEnseignePreparations } from "../actions/preparations.actions";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FilledInput from "@mui/material/FilledInput";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import AddMissingProduct from "../components/AddMissingProduct";

const AjouterPreparation = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // ------------------------------- RECUPERER PRODUITS, MP, CATEGORIES -------------------------------
  const listeProduits = useSelector((state) => state.allProductsReducer);
  const listeMP = useSelector((state) => state.MPreducer);
  const categoriesList = useSelector((state) => state.categoriesReducer);

  // ------------------------------- INFORMATIONS PREPARATION ------------------------------
  const [nom, setNom] = useState();
  const [categorie, setCategorie] = useState();
  const [poids, setPoids] = useState(0);
  const [cout, setCout] = useState();
  const [parts, setParts] = useState();
  const [commentaires, setCommentaires] = useState("");
  const [file, setFile] = useState();
  const [ingredients, setIngredients] = useState([]);
  const userData = useSelector((state) => state.userReducer);

  // --------------------------------- VARIABLES TEMPORAIRES -------------------------------
  const [ingredient, setIngredient] = useState();
  const [qtt, setQtt] = useState();

  // ------------------------------- MODIFIER LISTE INGREDIENTS ------------------------------
  const ajouterIngredient = (type) => {
    if (ingredient._id && qtt) {
      setPoids(parseInt(poids) + parseInt(qtt));

      if (type === "Matière première")
        setIngredients([
          ...ingredients,
          {
            id: ingredient._id,
            nom: ingredient.NOM_MP,
            quantity: qtt,
            type: type,
            allergenes: ingredient.ALLERGENE,
          },
        ]);
      else
        setIngredients([
          ...ingredients,
          {
            id: ingredient._id,
            nom: ingredient.denomination,
            quantity: qtt,
            type: type,
            allergenes: ingredient.allergene,
          },
        ]);
    }
  };

  const supprimerIngredient = (idDelete) => {
    setIngredients(ingredients.filter((i) => i.id !== idDelete));

    ingredients.forEach((ing) => {
      if (ing.id === idDelete) {
        setPoids(poids - parseInt(ing.quantity));
      }
    });
  };

  // ------------------------------------------- DIALOGS --------------------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openC, setOpenC] = useState(false);
  const handleClickOpenC = () => {
    setOpenC(true);
  };
  const handleCloseC = () => {
    setOpenC(false);
  };

  // --------------------------------- ENVOI DONNEES ---------------------------------
  const handlePreparation = async () => {
    if (nom && categorie && poids && cout && parts) {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/prepa/create`,
        withCredentials: true,
        data: {
          nom,
          categorie,
          poids,
          cout,
          parts,
          commentaires,
          ingredients,
          idEnseigne: userData.idEnseigne,
        },
      })
        .then((res) => {
          if (res.data.err) {
            alert(res.data.err);
          } else {
            if (file) {
              const data = new FormData();
              data.append("file", file);
              data.append("name", res.data.preparation._id);
              data.append("id", res.data.preparation._id);

              axios
                .post(
                  process.env.REACT_APP_SERVER_URL + `/api/prepa/upload-prepa`,
                  data
                )
                .then((res) => {
                  if (res.data.err) {
                    alert(res.data.err);
                  } else {
                    dispatch(getEnseignePreparations(userData.idEnseigne));
                    alert("Votre préparation a bien été ajoutée");
                    history.push("/listepreparations");
                  }
                })
                .catch((err) => {
                  alert(err);
                });
            } else {
              dispatch(getEnseignePreparations(userData.idEnseigne));
              alert("Votre préparation a bien été ajoutée");
              history.push("/listepreparations");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  return userData.type === "Restaurateur" ? (
    <div className="ajoutpreparation">
      <NavBar />
      <div className="inner">
        <h1>Ajouter une préparation</h1>
        <div className="infos">
          <div style={{ width: "100%" }}>
            <div className="field">
              <TextField
                className="input"
                label="Nom de la préparation"
                onChange={(e) => setNom(e.target.value)}
                variant="standard"
              />
            </div>

            <div className="field">
              {categoriesList && categoriesList.length > 0 && (
                <Autocomplete
                  className="input"
                  value={categorie}
                  id="disable-close-on-select"
                  options={categoriesList}
                  getOptionLabel={(option) => option.type}
                  onChange={(event, newValue) => {
                    setCategorie(newValue.type);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Catégorie"
                      variant="standard"
                    />
                  )}
                />
              )}
            </div>

            <div className="field">
              <TextField
                className="input"
                label="Coût estimé de la préparation"
                onChange={(e) => setCout(e.target.value)}
                type="number"
                variant="standard"
              />
            </div>

            <div className="field">
              <TextField
                className="input"
                label="Nombre de parts estimé"
                onChange={(e) => setParts(e.target.value)}
                type="number"
                variant="standard"
              />
            </div>

            <div className="field">
              <TextField
                className="input"
                id="standard-multiline-static"
                label="Etapes de préparation"
                multiline
                rows={4}
                onChange={(e) => setCommentaires(e.target.value)}
                variant="standard"
              />
            </div>

            <div className="field">
              <TextField
                className="input"
                label="Photo"
                onChange={(e) => setFile(e.target.files[0])}
                type="file"
                variant="standard"
              />
            </div>
          </div>

          {/* ------------------------------- TABLEAU INGREDIENTS ------------------------------ */}
          <div style={{ width: "100%" }}>
            <div className="field" style={{ width: "100%" }}>
              Poids total de la préparation : {poids} g.
            </div>
            <TableContainer
              sx={{ width: "100%", height: "fit-content" }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="head">
                      Ingrédients / Matières premières
                    </TableCell>
                    <TableCell align="center" className="head">
                      Quantité
                    </TableCell>
                    <TableCell align="center" className="head">
                      Supprimer
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ingredients &&
                    ingredients.length > 0 &&
                    ingredients.map((ingredient, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ padding: "10px" }} align="center">
                          {ingredient.nom}
                        </TableCell>
                        <TableCell sx={{ padding: "10px" }} align="center">
                          {ingredient.quantity} g
                        </TableCell>
                        <TableCell sx={{ padding: "10px" }} align="center">
                          <span className="trash">
                            <ion-icon
                              name="trash"
                              onClick={() => {
                                supprimerIngredient(ingredient.id);
                              }}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* --------------------------- BUTTONS AJOUTS & VALIDER -------------------------- */}
            <div className="btn">
              <button onClick={handleClickOpen}>
                Ajouter une matière première
              </button>
              <button onClick={handleClickOpenC}>Ajouter un ingrédient</button>
            </div>
            <div className="btn">
              <button onClick={handlePreparation}>
                <b>VALIDER LA PREPARATION </b>
                <span className="check">
                  <ion-icon name="checkmark" />
                </span>
              </button>
            </div>

            {/* ---------------------------------- DIALOG MP --------------------------------- */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Ajouter une matière première"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Autocomplete
                    className="input"
                    id="disable-close-on-select"
                    options={listeMP}
                    value={ingredient}
                    getOptionLabel={(option) => option.NOM_MP}
                    sx={{ width: 250, marginTop: "20px" }}
                    onChange={(event, newValue) => {
                      setIngredient(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ingrédient" />
                    )}
                  />
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                    <FilledInput
                      id="filled-adornment-weight"
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">g</InputAdornment>
                      }
                      onChange={(e) => setQtt(e.target.value)}
                      aria-describedby="filled-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    <FormHelperText id="filled-weight-helper-text">
                      Grammage
                    </FormHelperText>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                    ajouterIngredient("Matière première");
                  }}
                >
                  Ajouter
                </Button>
              </DialogActions>
            </Dialog>

            {/* ------------------------------- DIALOG PRODUITS ------------------------------ */}
            <Dialog
              open={openC}
              onClose={handleCloseC}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Ajouter un ingrédient"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {listeProduits && listeProduits.length > 0 && (
                    <Autocomplete
                      className="input"
                      id="disable-close-on-select"
                      options={listeProduits.filter(
                        (p) => p.state === "Validé"
                      )}
                      getOptionLabel={(option) => option.denomination}
                      value={ingredient}
                      sx={{ width: 400, marginTop: "20px" }}
                      onChange={(event, newValue) => {
                        setIngredient(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Ingrédient" />
                      )}
                    />
                  )}
                  <FormControl
                    sx={{ marginTop: 1, width: 400 }}
                    variant="filled"
                  >
                    <FilledInput
                      id="filled-adornment-weight"
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">g</InputAdornment>
                      }
                      onChange={(e) => setQtt(e.target.value)}
                      aria-describedby="filled-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    <FormHelperText id="filled-weight-helper-text">
                      Grammage
                    </FormHelperText>
                    <div>
                      <AddMissingProduct
                        props={nom}
                        handleCloseC={handleCloseC}
                      />
                    </div>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleCloseC();
                    ajouterIngredient("Produit");
                  }}
                >
                  Ajouter
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
      <br />
      <br />
      <br />
      Connectez-vous en tant que restaurateur pour accéder à cette page
      <br />
      <NavLink to="/">Retour</NavLink>
    </div>
  );
};

export default AjouterPreparation;
