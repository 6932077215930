import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [load, setLoad] = useState(false);

  const handleLogin = async () => {
    setLoad(true);
    if (email) {
      await axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/user/resetpassword`,
        withCredentials: true,
        data: {
          email,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data.err) {
            alert(res.data.err);
          } else {
            alert("Un mail de réinitialisation vous a été envoyé");
          }
        })
        .catch((err) => {
          alert("Email inconnu");
        });
    } else {
      alert("Veuillez entrer une adresse email");
    }
    setLoad(false);
  };

  return (
    <div className="forgotPassword">
      <div className="inner">
        <div className="ligne1">
          <Tooltip title="Accueil">
            <NavLink exact to="/accueil">
              <ArrowBackIosIcon
                sx={{
                  "& > :not(style)": {
                    color: "rgb(13, 19, 46)",
                  },
                }}
              />
            </NavLink>
          </Tooltip>
          <span>Informations de connexion oubliées</span>
        </div>
        <h5>
          Veuillez entrer votre adresse e-mail pour rechercher votre compte.
        </h5>
        <div className="data">
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="standard"
            className="field"
            type="email"
            sx={{
              "& > :not(style)": {
                color: "rgb(13, 19, 46)",
              },
            }}
          />
          <br />
          {load ? (
            <CircularProgress />
          ) : (
            <button onClick={handleLogin}>
              Envoyer un mail de réinitialisation
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
