import { React, useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { getAllEnseignes } from "../../actions/entreprises.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Inscription = () => {
  const history = useHistory();

  // --------------------------------- COORDONNEES PERSONELLES --------------------------------
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [idEnseigne, setIdEnseigne] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");

  // ------------------------------- COORDONNEES PROFESIONNELLES ------------------------------
  const [nomEnseigne, setNomEnseigne] = useState();
  const [adresseEnseigne, setAdresseEnseigne] = useState();
  const [cdpEnseigne, setCdpEnseigne] = useState();
  const [villeEnseigne, setVilleEnseigne] = useState();
  const [regionEnseigne, setRegionEnseigne] = useState();
  const [paysEnseigne, setPaysEnseigne] = useState();
  const [telEnseigne, setTelEnseigne] = useState();
  const [mailEnseigne, setMailEnseigne] = useState();
  const [webEnseigne, setWebEnseigne] = useState("");
  const [typeRest, setTypeRest] = useState();
  const [destination, setDestination] = useState();
  const [emplacement, setEmplacement] = useState();
  const [livraison, setLivraison] = useState();

  // -------------------------------- RECUPERER LES ENSEIGNES -------------------------------
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEnseignes());
  }, [dispatch]);
  const enseigneList = useSelector((state) => state.entreprisesReducer);
  const typeRestList = useSelector((state) => state.typeRestReducer);

  // ---------------------------------- AJOUTER UNE ENSEIGNE --------------------------------
  const handleAddCompany = () => {
    if (
      nomEnseigne &&
      adresseEnseigne &&
      telEnseigne &&
      cdpEnseigne &&
      villeEnseigne &&
      regionEnseigne &&
      telEnseigne &&
      mailEnseigne &&
      typeRest &&
      destination &&
      emplacement &&
      livraison
    ) {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/enseigne/create`,
        withCredentials: true,
        data: {
          nomEnseigne,
          adresse: adresseEnseigne,
          cdp: cdpEnseigne,
          ville: villeEnseigne,
          region: regionEnseigne,
          pays: paysEnseigne,
          telephone: telEnseigne,
          email: mailEnseigne,
          web: webEnseigne,
          typeRest,
          destination,
          emplacement,
          livraison,
        },
      })
        .then((res) => {
          if (res.data.err) {
            alert("Erreur lors de l'ajout de votre enseigne");
          } else {
            dispatch(getAllEnseignes());
            alert("Enseigne ajoutée avec succès");
            handleClose();
          }
        })
        .catch((err) => {
          alert(
            "Erreur lors de l'ajout de votre enseigne, veuillez vérifier que l'adresse email n'est pas encore utilisée"
          );
        });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  // --------------------------------------- INSCRIPTION -------------------------------------
  const handleInscription = () => {
    if (
      nom &&
      prenom &&
      telephone &&
      email &&
      identifiant &&
      password &&
      rpassword &&
      idEnseigne
    ) {
      if (password === rpassword) {
        axios({
          method: "post",
          url: process.env.REACT_APP_SERVER_URL + `/api/user/register/rest`,
          withCredentials: true,
          data: {
            nom,
            prenom,
            identifiant,
            telephone,
            email,
            password,
            idEnseigne,
          },
        })
          .then((res) => {
            if (res.data.errors) {
              alert(res.data.errors);
            } else {
              alert("Votre compte a bien été créé");
              history.push("/accueil");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("Les deux mots de passe ne correspondent pas");
      }
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  // ------------------------------------------- DIALOG -----------------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // --------------------------------------- FIND ENSEIGNE ID -------------------------------------
  const selectEnseigne = (selectedValue) => {
    enseigneList.forEach((esg) => {
      if (esg.nomEnseigne === selectedValue) {
        setIdEnseigne(esg._id);
      }
    });
  };

  return (
    <div className="info">
      <div>
        <h3>Informations personelles</h3>
        <ul>
          <li>
            <label>Nom</label>
            <input
              required
              type="text"
              onChange={(e) => setNom(e.target.value)}
            />
          </li>
          <li>
            <label>Prénom</label>
            <input
              required
              type="text"
              onChange={(e) => setPrenom(e.target.value)}
            />
          </li>
          <li>
            <label>Téléphone</label>
            <input
              required
              type="text"
              onChange={(e) => setTelephone(e.target.value)}
            />
          </li>
          <li>
            <label>Mail</label>
            <input
              required
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </li>
        </ul>
      </div>
      <div>
        <h3>Informations sur votre établissement</h3>
        <ul>
          <li className="company">
            {enseigneList && enseigneList.length > 0 && (
              <Autocomplete
                options={enseigneList}
                getOptionLabel={(option) => option.nomEnseigne}
                sx={{ width: 250 }}
                onInputChange={(e, newInputValue) => {
                  selectEnseigne(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Entreprise" />
                )}
              />
            )}
            <button onClick={handleClickOpen}>Ajouter une enseigne</button>

            {/* Dialog company */}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{"Ajouter une enseigne"}</DialogTitle>
              <DialogContent>
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Nom de l'enseigne"
                  onChange={(e) => setNomEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Adresse"
                  onChange={(e) => setAdresseEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Code postal"
                  onChange={(e) => setCdpEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Ville"
                  onChange={(e) => setVilleEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Région"
                  onChange={(e) => setRegionEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Pays"
                  onChange={(e) => setPaysEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Télephone du restaurant"
                  onChange={(e) => setTelEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Email du restaurant"
                  onChange={(e) => setMailEnseigne(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Site web"
                  onChange={(e) => setWebEnseigne(e.target.value)}
                />
                <Autocomplete
                  options={[
                    "Classique",
                    "Rapide",
                    "Restaurant à thème",
                    "Restauration collective",
                  ]}
                  onChange={(event, newValue) => {
                    setTypeRest(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%", marginTop: 1 }}
                      label="Catégorie de commerce alimentaire"
                    />
                  )}
                />
                {typeRestList && typeRestList.length > 0 && (
                  <>
                    <Autocomplete
                      options={typeRestList.filter(
                        (cat) => cat.type === typeRest
                      )}
                      getOptionLabel={(option) => option.destination}
                      onChange={(event, newValue) => {
                        setDestination(newValue.destination);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%", marginTop: 1 }}
                          label="Destination"
                        />
                      )}
                    />
                    <Autocomplete
                      options={typeRestList.filter(
                        (cat) => cat.type === "Emplacement"
                      )}
                      getOptionLabel={(option) => option.destination}
                      onChange={(event, newValue) => {
                        setEmplacement(newValue.destination);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%", marginTop: 1 }}
                          label="Emplacement"
                        />
                      )}
                    />
                    <Autocomplete
                      options={typeRestList.filter(
                        (cat) => cat.type === "Restauration livrée"
                      )}
                      getOptionLabel={(option) => option.destination}
                      onChange={(event, newValue) => {
                        setLivraison(newValue.destination);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%", marginTop: 1 }}
                          label="Restauration livrée"
                        />
                      )}
                    />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddCompany}>Ajouter</Button>
              </DialogActions>
            </Dialog>
          </li>
        </ul>
      </div>
      <div>
        <h3>Informations de connexion</h3>
        <ul>
          <li>
            <label>Identifiant</label>
            <input
              required
              type="text"
              onChange={(e) => setIdentifiant(e.target.value)}
            />
          </li>
          <li>
            <label>Mot de passe</label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </li>
          <li>
            <label>Confirmation du mot de passe</label>
            <input
              required
              type="password"
              onChange={(e) => setRpassword(e.target.value)}
            />
          </li>
          <button
            onClick={() => {
              handleInscription();
            }}
          >
            VALIDER
          </button>
        </ul>
      </div>
    </div>
  );
};

export default Inscription;
