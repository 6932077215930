import axios from "axios";

export const GET_TYPE_REST = "GET_TYPE_REST";

export const getTypeRest = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/typerest/get/all`)
      .then((res) => {
        dispatch({ type: GET_TYPE_REST, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
