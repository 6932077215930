import axios from "axios";

export const GET_PREPARATIONS_ENSEIGNE = "GET_PREPARATIONS_ENSEIGNE";

export const getEnseignePreparations = (idEnseigne) => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/prepa/get/prepa-enseigne/${idEnseigne}`)
      .then((res) => {
        dispatch({ type: GET_PREPARATIONS_ENSEIGNE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
