import { React, useState } from "react";
import NavBar from "../components/NavBar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProduits } from "../actions/produits.actions";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

// --------------------------------------- MUI ---------------------------------------
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";

const ModifierProduit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // ---------------------------- GET PRODUCT FROM PROPS & CAT -------------------------------
  const produit = props.location.state.produit;
  const mpFound = props.location.state.mp;
  const produitFound = props.location.state.prodFound;
  const allIA = props.location.state.allIA;
  const categoriesProductList = useSelector(
    (state) => state.categoriesProductsReducer
  );

  // --------------------------------------- USER DATA --------------------------------------
  const userData = useSelector((state) => state.userReducer);

  // ------------------------------------------ MP ------------------------------------------
  const [typeMPchoice, setTypeMPChoice] = useState("toutes");

  const listeMP = useSelector((state) => state.MPreducer);

  const [MP, setMP] = useState(mpFound);
  const [tempMP, setTempMP] = useState();

  const ajouterMP = () => {
    if (tempMP && !MP.find((mp) => mp.NOM_MP === tempMP.NOM_MP)) {
      setMP([...MP, tempMP]);

      if (tempMP.ALLERGENE !== "") {
        listeAllergenes.forEach((allergene, i) => {
          if (allergene.nom === tempMP.ALLERGENE) {
            allergene.present = true;
            setListeAllergenes(listeAllergenes);
          }
        });
      }

      setTempMP(null);
    }
  };
  const supprimerMP = (indexDelete) => {
    const filterMP = MP.filter((tempMP, i) => i !== indexDelete);
    setMP(filterMP);

    listeAllergenes.forEach((allergene, i) => {
      if (
        filterMP.find((mp) => mp.ALLERGENE === allergene.nom) ||
        produits.find((p) => p.allergene.includes(allergene.nom))
      ) {
        allergene.present = true;
      } else {
        allergene.present = false;
      }
    });
    setListeAllergenes(listeAllergenes);
  };

  // ------------------------------------------ FICHE TECHNIQUE ------------------------------------------
  const listeProduits = useSelector((state) => state.allProductsReducer);
  const [produits, setProduits] = useState(produitFound);
  const [tempProduit, setTempProduit] = useState();

  const ajouterProduit = () => {
    if (tempProduit) {
      console.log(produits);
      setProduits([...produits, tempProduit]);

      if (tempProduit.allergene) {
        listeAllergenes.forEach((allergene, i) => {
          if (tempProduit.allergene.includes(allergene.nom)) {
            allergene.present = true;
            setListeAllergenes(listeAllergenes);
          }
        });
      }

      setTempProduit(null);
    }
  };
  const supprimerProduit = (indexDelete) => {
    const filterProduit = produits.filter(
      (tempProduit, i) => i !== indexDelete
    );
    setProduits(filterProduit);

    listeAllergenes.forEach((allergene, i) => {
      if (
        filterProduit.find((p) => p.allergene.includes(allergene.nom)) ||
        MP.find((mp) => mp.ALLERGENE === allergene.nom)
      ) {
        allergene.present = true;
      } else {
        allergene.present = false;
      }
    });
    setListeAllergenes(listeAllergenes);
  };

  // --------------------------------------- ADDITIFS ---------------------------------------
  const listeAdditifs = useSelector((state) => state.additifsReducer);

  const [additifs, setAdditifs] = useState(produit.additifs);
  const [additifName, setAdditifName] = useState();

  const ajouterAdditif = () => {
    setAdditifs([...additifs, { code: additifName }]);
  };
  const supprimerAdditif = (indexDelete) => {
    setAdditifs((additifs) =>
      additifs.filter((additif, i) => i !== indexDelete)
    );
  };

  // --------------------------------- ALERGENES ---------------------------------
  const [listeAllergenes, setListeAllergenes] = useState([
    { nom: "Gluten", present: produit.allergene.includes("Gluten") },
    { nom: "Oeuf", present: produit.allergene.includes("Oeuf") },
    { nom: "Lait", present: produit.allergene.includes("Lait") },
    { nom: "Arachides", present: produit.allergene.includes("Arachides") },
    { nom: "Poissons", present: produit.allergene.includes("Poissons") },
    { nom: "Soja", present: produit.allergene.includes("Soja") },
    {
      nom: "Fruits à coque",
      present: produit.allergene.includes("Fruits à coque"),
    },
    { nom: "Anhydride", present: produit.allergene.includes("Anhydride") },
    { nom: "Crustacés", present: produit.allergene.includes("Crustacés") },
    { nom: "Moutarde", present: produit.allergene.includes("Moutarde") },
    { nom: "Mollusques", present: produit.allergene.includes("Mollusques") },
    { nom: "Céleri", present: produit.allergene.includes("Céleri") },
    { nom: "Sésame", present: produit.allergene.includes("Sésame") },
  ]);

  // --------------------------------- DIALOGS ---------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openC, setOpenC] = useState(false);
  const handleClickOpenC = () => {
    setOpenC(true);
  };
  const handleCloseC = () => {
    setOpenC(false);
  };
  const [openF, setOpenF] = useState(false);
  const handleClickOpenF = () => {
    setOpenF(true);
  };
  const handleCloseF = () => {
    setOpenF(false);
  };

  // ------------------------------- VARIABLES DONNEES -------------------------------
  const [denomination, setDenomination] = useState(produit.denomination);
  const [code, setCode] = useState(produit.code);
  const [marque, setMarque] = useState(produit.marque);
  const [description, setDescription] = useState(produit.description);
  const [file, setFile] = useState(produit.file);
  const [type, setType] = useState(produit.type);
  const [categorie, setCategorie] = useState(produit.categorie);
  const [sousCategorie, setSousCategorie] = useState(produit.sousCategorie);
  const [pays, setPays] = useState(produit.pays);
  const [contenant, setContenant] = useState(produit.contenant);
  const [KJ, setKJ] = useState(produit.KJ);
  const [KC, setKC] = useState(produit.KC);
  const [matiereGrasse, setMatiereGrasse] = useState(produit.matiereGrasse);
  const [acidesGrasSatures, setAcidesGrasSatures] = useState(
    produit.acidesGrasSatures
  );
  const [acidesGrasPolyInsatures, setAcidesGrasPolyInsatures] = useState(
    produit.acidesGrasPolyInsatures
  );
  const [acidesGrasMonoInsatures, setAcidesGrasMonoInsatures] = useState(
    produit.acidesGrasMonoInsatures
  );
  const [acidesGrasTrans, setAcidesGrasTrans] = useState(
    produit.acidesGrasTrans
  );
  const [glucides, setGlucides] = useState(produit.glucides);
  const [sucres, setSucres] = useState(produit.sucres);
  const [fibres, setFibres] = useState(produit.fibres);
  const [proteines, setProteines] = useState(produit.proteines);
  const [sel, setSel] = useState(produit.sel);
  const [conservation, setConservation] = useState(produit.conservation);
  const [dateLimiteConsommation, setDateLimiteConsommation] = useState(
    produit.dateLimiteConsommation
  );
  const [dateLimiteConsommationAO, setDateLimiteConsommationAO] = useState(
    produit.dateLimiteConsommationAO
  );
  const [AEN, setAEN] = useState(produit.AEN);
  const [poidsNetProduit, setPoidsNetProduit] = useState(
    produit.poidsNetProduit
  );
  const [poidsBrutProduit, setPoidsBrutProduit] = useState(
    produit.poidsBrutProduit
  );
  const [longueurCarton, setLongueurCarton] = useState(produit.longueurCarton);
  const [largeurCarton, setLargeurCarton] = useState(produit.longueurCarton);
  const [hauteurCarton, setHauteurCarton] = useState(produit.hauteurCarton);
  const [poidsNetLot, setPoidsNetLot] = useState(produit.poidsNetLot);
  const [poidsCartonVide, setPoidsCartonVide] = useState(
    produit.poidsCartonVide
  );
  const [poidsBrutCarton, setPoidsBrutCarton] = useState(
    produit.poidsBrutCarton
  );
  const [hauteurPaletteVide, setHauteurPaletteVide] = useState(
    produit.hauteurPaletteVide
  );
  const [nbPiecesParCarton, setNnbPiecesParCarton] = useState(
    produit.nbPiecesParCarton
  );
  const [nbCartonsParCouche, setNbCartonsParCouche] = useState(
    produit.nbCartonsParCouche
  );
  const [nbCouches, setNbCouches] = useState(produit.nbCouches);
  const [germes, setGermes] = useState(produit.germes);
  const [criteres, setCriteres] = useState(produit.criteres);
  const [unites, setUnites] = useState(produit.unites);
  const [commentaires, setCommentaires] = useState(produit.commentaires);
  const [nomRedaction, setNomRedaction] = useState(produit.nomRedaction);
  const [prenomRedaction, setPrenomRedaction] = useState(
    produit.prenomRedaction
  );
  const [dateRedaction, setDateRedaction] = useState(produit.dateRedaction);
  const [nomValidation, setNomValidation] = useState(produit.nomValidation);
  const [prenomValidation, setPrenomValidation] = useState(
    produit.prenomValidation
  );
  const [dateValidation, setDateValidation] = useState(produit.dateValidation);

  // --------------------------------- ENVOI FILE ---------------------------------
  const handleFile = async (id) => {
    const data = new FormData();
    data.append("file", file);
    data.append("name", id);
    data.append("id", id);

    await axios
      .post(
        process.env.REACT_APP_SERVER_URL + `/api/product/upload-product`,
        data
      )
      .then((res) => {
        if (res.data.err) {
          alert(res.data.err);
        } else {
          dispatch(getProduits(userData.idEntreprise));
          alert("Votre produit a bien été modifié");
          history.push("/listeproduits");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  // --------------------------------- ENVOI DONNEES ---------------------------------
  const handleProduct = async (state) => {
    const allMP = MP.map((mp) => mp.ALLERGENE);
    let allProd = [];
    produits.map((p) => p.allergene.map((a) => allProd.push(a)));

    if (state === "Brouillon" || (state === "Validé" && denomination && code))
      await axios({
        method: "post",
        url:
          process.env.REACT_APP_SERVER_URL +
          `/api/product/update/${produit._id}`,
        withCredentials: true,
        data: {
          denomination,
          code,
          marque,
          description,
          type,
          categorie,
          sousCategorie,
          pays,
          contenant,
          KJ,
          KC,
          matiereGrasse,
          acidesGrasSatures,
          acidesGrasPolyInsatures,
          acidesGrasMonoInsatures,
          acidesGrasTrans,
          glucides,
          sucres,
          fibres,
          proteines,
          sel,
          conservation,
          dateLimiteConsommation,
          dateLimiteConsommationAO,
          AEN,
          poidsNetProduit,
          poidsBrutProduit,
          longueurCarton,
          largeurCarton,
          hauteurCarton,
          poidsNetLot,
          poidsCartonVide,
          poidsBrutCarton,
          hauteurPaletteVide,
          nbPiecesParCarton,
          nbCartonsParCouche,
          nbCouches,
          germes,
          criteres,
          unites,
          commentaires,
          nomRedaction,
          prenomRedaction,
          dateRedaction,
          nomValidation,
          prenomValidation,
          dateValidation,
          additifs,
          idEntreprise: userData.idEntreprise,
          matierePremiere: MP.map((mp) => mp.NOM_MP),
          allergene: allMP.concat(allProd).concat(allIA),
          traceAllergene: tracesAllergenesPresents,
          produits: produits.map((p) => p._id),
          state,
        },
      })
        .then((res) => {
          if (res.data.err) {
            alert(res.data.err);
          } else {
            if (file) {
              handleFile(res.data._id);
            } else {
              dispatch(getProduits(userData.idEntreprise));
              alert("Votre produit a bien été modifié");
              history.push("/listeproduits");
            }
          }
        })
        .catch((err) => {
          alert(err);
          console.log(err);
        });
    else
      alert(
        "Veuillez remplir la dénomination et le code avant de publier votre produit"
      );
  };

  // ------------------------------- MODIFIER LISTE TRACES ALLERGENES ------------------------------
  const [tracesAllergenesPresents, setTracesAllergenesPresents] = useState(
    produit.traceAllergene
  );

  const modifierTracesAllergene = (index) => {
    // ajout
    if (!tracesAllergenesPresents.includes(listeAllergenes[index].nom)) {
      setTracesAllergenesPresents([
        ...tracesAllergenesPresents,
        listeAllergenes[index].nom,
      ]);
    }
    // suppression
    else {
      setTracesAllergenesPresents(
        tracesAllergenesPresents.filter((a) => a !== listeAllergenes[index].nom)
      );
    }
  };

  return userData.type === "Producteur" ? (
    <div className="ajoutproduit">
      <div>
        <NavBar />
        <div className="inner">
          <h1>Modification d'une fiche produit</h1>

          <h3>Identification du produit</h3>

          <div className="identif">
            <p>
              Remplissez les données du produit que vous souhaitez intégrer à
              votre liste. Nous nous occuperons de réviser vos données afin de
              vous aider à établir une fiche standard répondant précisement aux
              norme en vigueur.
            </p>
          </div>

          {/* ----------------------------------- IDENTIFICATION ----------------------------------- */}
          <div style={{ width: "95%" }}>
            <FormControl sx={{ marginLeft: "10px", marginTop: "30px" }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Votre produit est :
              </FormLabel>
              <RadioGroup className="radio" defaultValue={produit.type}>
                <FormControlLabel
                  value="Matière première"
                  control={<Radio />}
                  label="Une matière première"
                  onChange={() => setType("Matière première")}
                />
                <FormControlLabel
                  value="Produit élaboré"
                  control={<Radio />}
                  label="Un produit élaboré"
                  onChange={() => setType("Produit élaboré")}
                />
              </RadioGroup>
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Dénomination*"
                  defaultValue={produit.denomination}
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setDenomination(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Code*"
                  defaultValue={produit.code}
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Marque"
                  defaultValue={produit.marque}
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setMarque(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Pays d'origine"
                  defaultValue={produit.pays}
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setPays(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Contenant"
                  defaultValue={produit.contenant}
                  sx={{ margin: 1, width: "100%" }}
                  onChange={(e) => setContenant(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  component={Paper}
                  label="Image"
                  sx={{
                    margin: 1,
                    width: "85%",
                    paddingLeft: "15%",
                  }}
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  value={categorie}
                  defaultValue={produit.categorie}
                  options={[
                    ...new Set(
                      categoriesProductList.map((c) => c.alim_grp_nom_fr)
                    ),
                  ]}
                  onChange={(event, newValue) => {
                    setCategorie(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Catégorie"
                      sx={{ margin: 1 }}
                      component={Paper}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={sousCategorie}
                  defaultValue={produit.sousCategorie}
                  options={categoriesProductList
                    .filter((c) => c.alim_grp_nom_fr === categorie)
                    .map((c) => c.alim_ssgrp_nom_fr)}
                  onChange={(event, newValue) => {
                    setSousCategorie(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sous catégorie"
                      sx={{ margin: 1 }}
                      component={Paper}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <TextField
              component={Paper}
              label="Description du produit"
              sx={{ margin: 1, width: "100%" }}
              onChange={(e) => setDescription(e.target.value)}
              defaultValue={produit.description}
              multiline
              rows={4}
            />

            {/* ----------------------------------------- TABLEAU INGREDIENTS ----------------------------------------- */}
            <TableContainer
              component={Paper}
              sx={{
                width: "50%",
                display: "block",
                margin: "auto",
                marginTop: 5,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Ingrédients
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Type
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Supprimer
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* -------------------------- MP -------------------------- */}
                  {MP.map((row, i) => (
                    <TableRow
                      key={row.NOM_MP}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.NOM_MP}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        Matière première
                      </TableCell>
                      <TableCell align="center">
                        <ion-icon
                          name="trash"
                          onClick={() => {
                            supprimerMP(i);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* -------------------------- Ingrédients pré élaborés -------------------------- */}
                  {produits.map((row, i) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {
                          listeProduits.find(
                            (pFromList) => pFromList._id === row._id
                          ).denomination
                        }
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        Ingrédient pré élaboré
                      </TableCell>
                      <TableCell align="center">
                        <ion-icon
                          name="trash"
                          onClick={() => {
                            supprimerProduit(i);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* -------------------------- Additifs -------------------------- */}
                  {additifs.map((row, i) => (
                    <TableRow
                      key={row.code}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.code}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        Additif
                      </TableCell>
                      <TableCell align="center">
                        <ion-icon
                          name="trash"
                          onClick={() => {
                            supprimerAdditif(i);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={handleClickOpen}
                style={{ margin: "10px", border: 0 }}
              >
                + Ajouter une matière première
              </button>
              <button
                onClick={handleClickOpenF}
                style={{ margin: "10px", border: 0 }}
              >
                + Ajouter un ingrédient pré élaboré
              </button>
              <button
                onClick={handleClickOpenC}
                style={{ margin: "10px", border: 0 }}
              >
                + Ajouter un additif
              </button>
            </div>
          </div>

          {/* ----------------------------------------- DIALOG MP ----------------------------------------- */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Ajouter une matière première"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormControl>
                  <FormControl>
                    <RadioGroup
                      row
                      value={typeMPchoice}
                      defaultValue="toutes"
                      onChange={(e) => setTypeMPChoice(e.target.value)}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ width: "100%" }}
                    >
                      <FormControlLabel
                        label="Aucun filtre"
                        control={<Radio />}
                        value="toutes"
                      />
                      <FormControlLabel
                        label="MP brutes"
                        control={<Radio />}
                        value="brute"
                      />
                      <FormControlLabel
                        label="MP élaborées"
                        control={<Radio />}
                        value="elabore"
                      />
                    </RadioGroup>
                  </FormControl>
                  <br />
                  {listeMP && listeMP.length > 0 && (
                    <Autocomplete
                      id="disable-close-on-select"
                      value={tempMP}
                      options={
                        typeMPchoice === "toutes"
                          ? listeMP
                          : listeMP.filter((m) => m.type === typeMPchoice)
                      }
                      getOptionLabel={(option) => option.NOM_MP}
                      sx={{ width: "100%" }}
                      onChange={(event, newValue) => {
                        setTempMP(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Matières premières" />
                      )}
                    />
                  )}
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  ajouterMP();
                }}
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>

          {/* ----------------------------------------- DIALOG FT ----------------------------------------- */}
          <Dialog open={openF} onClose={handleCloseF}>
            <DialogTitle>{"Ajouter un ingrédient existant"}</DialogTitle>
            <DialogContent>
              <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                {listeProduits && listeProduits.length > 0 && (
                  <Autocomplete
                    options={listeProduits.filter((p) => p.state === "Validé")}
                    getOptionLabel={(option) =>
                      option.denomination + " - " + option.marque
                    }
                    value={tempProduit}
                    sx={{ width: 250 }}
                    onChange={(event, newValue) => {
                      setTempProduit(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ingrédient" />
                    )}
                  />
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseF();
                  ajouterProduit();
                }}
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>

          {/* ----------------------------------------- DIALOG ADDITIFS ----------------------------------------- */}
          <Dialog open={openC} onClose={handleCloseC}>
            <DialogTitle>{"Ajouter un additif"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormControl sx={{ m: 1 }}>
                  <Autocomplete
                    id="disable-close-on-select"
                    options={listeAdditifs}
                    getOptionLabel={(option) =>
                      option.code + " - " + option.nom
                    }
                    sx={{ width: 250 }}
                    onChange={(event, newValue) => {
                      setAdditifName(newValue.code);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Additifs" />
                    )}
                  />
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseC();
                  ajouterAdditif();
                }}
              >
                Ajouter
              </Button>
            </DialogActions>
          </Dialog>

          <ul>
            {/* ----------------------------------------- ALLERGENES ----------------------------------------- */}
            {/* ALLERGENES */}
            <li key="" className="allergenes">
              <FormGroup>
                <label>Allergènes :</label>
                {listeAllergenes.map((a) => (
                  <FormControlLabel
                    control={<Checkbox disabled checked={a.present} />}
                    label={a.nom}
                  />
                ))}
              </FormGroup>

              {/* TRACES D'ALLERGENES */}
              <FormGroup>
                <label>
                  Traces d'allergènes susceptibles d'être présents :
                </label>
                {listeAllergenes.map((a, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={produit.traceAllergene.includes(a.nom)}
                        onChange={() => modifierTracesAllergene(i)}
                      />
                    }
                    label={a.nom}
                  />
                ))}
              </FormGroup>
            </li>
          </ul>

          {/* ----------------------------------------- VAL NUT ----------------------------------------- */}
          <h3>Valeurs nutritives</h3>
          <p className="imp">Taux exprimés pour 100g</p>
          <ul className="valNut1">
            <div>
              <li>
                <label>Matière grasse : </label>
                <input
                  defaultValue={produit.matiereGrasse}
                  type="number"
                  onChange={(e) => setMatiereGrasse(e.target.value)}
                />
              </li>
              <li className="sousChamp">
                <label>Acides gras trans : </label>
                <input
                  defaultValue={produit.acidesGrasTrans}
                  type="number"
                  onChange={(e) => setAcidesGrasTrans(e.target.value)}
                />
              </li>
              <li className="sousChamp">
                <label>Acides gras saturés : </label>
                <input
                  defaultValue={produit.acidesGrasSatures}
                  type="number"
                  onChange={(e) => setAcidesGrasSatures(e.target.value)}
                />
              </li>
              <li className="sousChamp">
                <label>Acides gras mono insaturés : </label>
                <input
                  defaultValue={produit.acidesGrasMonoInsatures}
                  type="number"
                  onChange={(e) => setAcidesGrasMonoInsatures(e.target.value)}
                />
              </li>
              <li className="sousChamp">
                <label>Acides gras poly insaturés : </label>
                <input
                  defaultValue={produit.acidesGrasPolyInsatures}
                  type="number"
                  onChange={(e) => setAcidesGrasPolyInsatures(e.target.value)}
                />
              </li>
              <li>
                <label>Sel : </label>
                <input
                  defaultValue={produit.sel}
                  type="number"
                  onChange={(e) => setSel(e.target.value)}
                />
              </li>
            </div>

            <div>
              <li>
                <label>Kilo Joules : </label>
                <input
                  defaultValue={produit.KJ}
                  type="number"
                  onChange={(e) => setKJ(e.target.value)}
                />
              </li>
              <li>
                <label>Kilo calories : </label>
                <input
                  defaultValue={produit.KC}
                  type="number"
                  onChange={(e) => setKC(e.target.value)}
                />
              </li>
              <li>
                <label>Glucides assimilés : </label>
                <input
                  defaultValue={produit.glucides}
                  type="number"
                  onChange={(e) => setGlucides(e.target.value)}
                />
              </li>
              <li className="sousChamp">
                <label>Dont sucre : </label>
                <input
                  defaultValue={produit.sucres}
                  type="number"
                  onChange={(e) => setSucres(e.target.value)}
                />
              </li>
              <li>
                <label>Fibres alimentaires : </label>
                <input
                  defaultValue={produit.fibres}
                  type="number"
                  onChange={(e) => setFibres(e.target.value)}
                />
              </li>

              <li>
                <label>Protéines : </label>
                <input
                  defaultValue={produit.proteines}
                  type="number"
                  onChange={(e) => setProteines(e.target.value)}
                />
              </li>
            </div>
          </ul>

          <h3>Informations logistiques</h3>
          <ul className="infoLog">
            <li className="conditions">
              <label>Conditions de conservation et d'utilisation : </label>
              <input
                defaultValue={produit.conservation}
                type="text"
                onChange={(e) => setConservation(e.target.value)}
              />
            </li>

            <li>
              <label>Date limite de consommation : </label>
              <input
                defaultValue={produit.dateLimiteConsommation}
                type="date"
                onChange={(e) => setDateLimiteConsommation(e.target.value)}
              />
            </li>

            <li>
              <label>Date limite de consommation après ouverture : </label>
              <input
                defaultValue={produit.dateLimiteConsommationAO}
                type="date"
                onChange={(e) => setDateLimiteConsommationAO(e.target.value)}
              />
            </li>

            <li>
              <label>Code AEN : </label>
              <input
                defaultValue={produit.AEN}
                type="text"
                onChange={(e) => setAEN(e.target.value)}
              />
            </li>
          </ul>

          <p className="impPoids">
            Toutes les valeurs doivent être exprimées en gramme
          </p>
          <ul className="poids">
            <li>
              <label>Poids net produit (g) : </label>
              <input
                defaultValue={produit.poidsNetProduit}
                type="number"
                onChange={(e) => setPoidsNetProduit(e.target.value)}
              />
            </li>

            <li>
              <label>Poids brut produit (g) : </label>
              <input
                defaultValue={produit.poidsBrutProduit}
                type="number"
                onChange={(e) => setPoidsBrutProduit(e.target.value)}
              />
            </li>
          </ul>

          <h3>Dimensions du carton en cm</h3>
          <ul className="poids">
            <li>
              <label>Longueur : </label>
              <input
                defaultValue={produit.longueurCarton}
                type="number"
                onChange={(e) => setLongueurCarton(e.target.value)}
              />
            </li>
            <li>
              <label>Largeur : </label>
              <input
                defaultValue={produit.largeurCarton}
                type="number"
                onChange={(e) => setLargeurCarton(e.target.value)}
              />
            </li>
            <li>
              <label>Hauteur : </label>
              <input
                defaultValue={produit.hauteurCarton}
                type="number"
                onChange={(e) => setHauteurCarton(e.target.value)}
              />
            </li>
          </ul>

          <ul className="poids">
            <li>
              <label>Poids net du lot : </label>
              <input
                defaultValue={produit.poidsNetLot}
                type="number"
                onChange={(e) => setPoidsNetLot(e.target.value)}
              />
            </li>
            <li>
              <label>Poids du carton vide : </label>
              <input
                defaultValue={produit.poidsCartonVide}
                type="number"
                onChange={(e) => setPoidsCartonVide(e.target.value)}
              />
            </li>
            <li>
              <label>Poids brut du carton : </label>
              <input
                defaultValue={produit.poidsBrutCarton}
                type="number"
                onChange={(e) => setPoidsBrutCarton(e.target.value)}
              />
            </li>
          </ul>

          <h3>Informations palettes</h3>

          <ul className="paletteVide">
            <li>
              <label>Hauteur palette vide : </label>
              <input
                defaultValue={produit.hauteurPaletteVide}
                type="number"
                onChange={(e) => setHauteurPaletteVide(e.target.value)}
              />{" "}
              cm. (Palette ISO 13.1 cm, Palette EPAL 14.4 cm ...)
            </li>
          </ul>

          <ul className="valNut">
            <li>
              <label>Nombre de pièces par carton : </label>
              <input
                defaultValue={produit.nbPiecesParCarton}
                type="number"
                onChange={(e) => setNnbPiecesParCarton(e.target.value)}
              />
            </li>
            <li>
              <label className="calcul">
                Hauteur de la palette :{" "}
                {parseInt(hauteurCarton) * parseInt(nbCouches) +
                  parseInt(hauteurPaletteVide)}{" "}
                cm.
              </label>
            </li>
            <li>
              <label>Nombre de cartons par couche : </label>
              <input
                defaultValue={produit.nbCartonsParCouche}
                type="number"
                onChange={(e) => setNbCartonsParCouche(e.target.value)}
              />
            </li>
            <li>
              <label className="calcul">
                Nombre de cartons par palette : {nbCartonsParCouche * nbCouches}{" "}
                cm.
              </label>
            </li>
            <li>
              <label>Nombre de couches : </label>
              <input
                defaultValue={produit.nbCouches}
                type="number"
                onChange={(e) => setNbCouches(e.target.value)}
              />
            </li>
          </ul>

          <h3>Informations microbiologiques</h3>
          <ul className="poidsMicrobio">
            <li>
              <label>Germes : </label>
              <input
                defaultValue={produit.germes}
                type="text"
                onChange={(e) => setGermes(e.target.value)}
              />
            </li>
            <li>
              <label>Critère : </label>
              <input
                defaultValue={produit.criteres}
                type="text"
                onChange={(e) => setCriteres(e.target.value)}
              />
            </li>
            <li>
              <label>Unité : </label>
              <input
                defaultValue={produit.unites}
                type="text"
                onChange={(e) => setUnites(e.target.value)}
              />
            </li>
          </ul>

          <h3>Commentaires</h3>
          <textarea
            defaultValue={produit.commentaires}
            onChange={(e) => setCommentaires(e.target.value)}
          />

          <h3>Informations de contrôle</h3>
          <ul>
            <li className="tableControle">
              <div>
                <label>Rédaction : </label>
                <br />
                <label>Validation et approbation : </label>
              </div>
              <div className="table">
                <div className="row">
                  <input
                    defaultValue={produit.nomRedaction}
                    type="text"
                    placeholder="Nom"
                    onChange={(e) => setNomRedaction(e.target.value)}
                  />
                  <input
                    defaultValue={produit.prenomRedaction}
                    type="text"
                    placeholder="Prénom"
                    onChange={(e) => setPrenomRedaction(e.target.value)}
                  />
                  <input
                    defaultValue={produit.dateRedaction}
                    type="date"
                    onChange={(e) => setDateRedaction(e.target.value)}
                  />
                </div>
                <div className="barre"></div>
                <div className="row">
                  <input
                    defaultValue={produit.nomValidation}
                    type="text"
                    placeholder="Nom"
                    onChange={(e) => setNomValidation(e.target.value)}
                  />
                  <input
                    defaultValue={produit.prenomValidation}
                    type="text"
                    placeholder="Prénom"
                    onChange={(e) => setPrenomValidation(e.target.value)}
                  />
                  <input
                    defaultValue={produit.dateValidation}
                    type="date"
                    onChange={(e) => setDateValidation(e.target.value)}
                  />
                </div>
              </div>
            </li>
          </ul>

          <div className="actions">
            <>
              <button
                onClick={() => {
                  handleProduct("Brouillon");
                }}
              >
                Modifier en brouillon
              </button>
              <button
                onClick={() => {
                  handleProduct("Validé");
                }}
              >
                Modifier et publier
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="progess">
      <CircularProgress />
      <br />
      <br />
      <br />
      Connectez-vous en tant que producteur pour accéder à cette page
      <br />
      <NavLink to="/">Retour</NavLink>
    </div>
  );
};

export default ModifierProduit;
