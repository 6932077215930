import { React, useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { getAllEntreprises } from "../../actions/entreprises.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Inscription = () => {
  const history = useHistory();

  // --------------------------------- COORDONNEES PERSONELLES ---------------------------------
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [telephone, setTelephone] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [identifiant, setIdentifiant] = useState();
  const [password, setPassword] = useState();
  const [rpassword, setRpassword] = useState();

  // -------------------------------- COORDONNEES PROFESIONNELLES -------------------------------
  const [societe, setSociete] = useState();
  const [siret, setSiret] = useState();
  const [tva, setTva] = useState();
  const [adresse, setAdresse] = useState();
  const [codePostal, setCodePostal] = useState();
  const [ville, setVille] = useState();
  const [pays, setPays] = useState();
  const [telSociete, setTelSociete] = useState();
  const [emailSociete, setEmailSociete] = useState();
  const [site, setSite] = useState("");

  // -------------------------------- RECUPERER LES ENTREPRISES ------------------------------
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEntreprises());
  }, [dispatch]);
  const companyList = useSelector((state) => state.entreprisesReducer);

  // ----------------------------------- AJOUTER UNE ENTREPRISE ---------------------------------
  const handleAddCompany = () => {
    if (societe && siret && tva && adresse && codePostal && ville && pays) {
      axios({
        method: "post",
        url: process.env.REACT_APP_SERVER_URL + `/api/entreprise/create`,
        withCredentials: true,
        data: {
          nom: "",
          prenom: "",
          nomSociete: societe,
          siret,
          numTva: tva,
          adresse,
          cdp: codePostal,
          ville,
          pays,
          tel: telSociete,
          mail: emailSociete,
          web: site,
        },
      })
        .then((res) => {
          if (res.data.err) {
            alert("L'entreprise n'a pas pu être ajoutée");
          } else {
            dispatch(getAllEntreprises());
            alert("Entreprise ajoutée avec succès");
            handleClose();
          }
        })
        .catch(() => {
          alert("L'entreprise n'a pas pu être ajoutée");
        });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  // --------------------------------------- INSCRIPTION -------------------------------------
  const handleInscription = () => {
    if (
      nom &&
      prenom &&
      telephone &&
      email &&
      identifiant &&
      password &&
      rpassword &&
      company
    ) {
      if (password === rpassword) {
        axios({
          method: "post",
          url: process.env.REACT_APP_SERVER_URL + `/api/user/register/prod`,
          withCredentials: true,
          data: {
            nom,
            prenom,
            identifiant,
            telephone,
            email,
            password,
            idEntreprise: company,
          },
        })
          .then((res) => {
            if (res.data.errors) {
              alert(res.data.errors);
            } else {
              alert("Votre compte a bien été créé");
              history.push("/accueil");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("Les deux mots de passe ne correspondent pas");
      }
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  // ------------------------------------------- DIALOG -----------------------------------------
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // --------------------------------------- FIND COMPANY ID -------------------------------------
  const selectCompany = (selectedValue) => {
    companyList.forEach((cmp) => {
      if (cmp.nomSociete === selectedValue) {
        setCompany(cmp._id);
      }
    });
  };

  return (
    <div className="info">
      <div className="perso">
        <h3>Informations personelles</h3>
        <ul>
          <li>
            <label>Nom</label>
            <input
              required
              type="text"
              onChange={(e) => setNom(e.target.value)}
            />
          </li>
          <li>
            <label>Prénom</label>
            <input
              required
              type="text"
              onChange={(e) => setPrenom(e.target.value)}
            />
          </li>
          <li>
            <label>Téléphone</label>
            <input
              required
              type="text"
              onChange={(e) => setTelephone(e.target.value)}
            />
          </li>
          <li>
            <label>Mail</label>
            <input
              required
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </li>
        </ul>
      </div>
      <div>
        <h3>Informations sur votre entreprise</h3>
        <ul>
          <li className="company">
            {companyList && companyList.length > 0 && (
              <Autocomplete
                options={companyList}
                getOptionLabel={(option) => option.nomSociete}
                sx={{ width: 250 }}
                onInputChange={(e, newInputValue) => {
                  selectCompany(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Entreprise" />
                )}
              />
            )}
            <button onClick={handleClickOpen}>Ajouter une entreprise</button>

            {/* Dialog company */}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{"Ajouter une entreprise"}</DialogTitle>
              <DialogContent>
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Nom de la société"
                  onChange={(e) => setSociete(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="N° siret"
                  onChange={(e) => setSiret(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="N° TVA intracommunautaire"
                  onChange={(e) => setTva(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Adresse"
                  onChange={(e) => setAdresse(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Code postal"
                  onChange={(e) => setCodePostal(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Ville"
                  onChange={(e) => setVille(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Pays"
                  onChange={(e) => setPays(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Télephone société"
                  onChange={(e) => setTelSociete(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Email société"
                  onChange={(e) => setEmailSociete(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", marginTop: 1 }}
                  label="Site web"
                  onChange={(e) => setSite(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddCompany}>Ajouter</Button>
              </DialogActions>
            </Dialog>
          </li>
        </ul>
      </div>
      <div>
        <h3>Informations de connexion</h3>
        <ul>
          <li>
            <label>Nom d'utilisateur</label>
            <input
              required
              type="text"
              onChange={(e) => setIdentifiant(e.target.value)}
            />
          </li>
          <li>
            <label>Mot de passe</label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </li>
          <li>
            <label>Confirmation du mot de passe</label>
            <input
              required
              type="password"
              onChange={(e) => setRpassword(e.target.value)}
            />
          </li>
          <button onClick={handleInscription}>VALIDER</button>
        </ul>
      </div>
    </div>
  );
};

export default Inscription;
