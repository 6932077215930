import axios from "axios";

export const GET_ALL_PN = "GET_ALL_PN";

export const getAllPN = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/productneed/get/all`)
      .then((res) => {
        dispatch({ type: GET_ALL_PN, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
