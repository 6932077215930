import { GET_ENTREPRISES } from "../actions/entreprises.actions";
import { GET_ENSEIGNES } from "../actions/entreprises.actions";

const initialState = {};

export default function entreprisesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ENTREPRISES:
      return action.payload;
    case GET_ENSEIGNES:
      return action.payload;
    default:
      return state;
  }
}
