import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFound from "../../pages/NotFound";
import Connection from "../../pages/Connection";
import Inscription from "../../pages/Inscription";
import Accueil from "../../pages/Accueil";
import AjoutProduit from "../../pages/AjoutProduit";
import ListeProduits from "../../pages/ListeProduits";
import ComptePerso from "../../pages/ComptePerso";
import ModifierProduit from "../../pages/ModifierProduit";
import AjouterPreparation from "../../pages/AjouterPreparation";
import ListePreparations from "../../pages/ListePreparations";
import Carte from "../../pages/Carte";
import ForgotPassword from "../../pages/ForgotPassword";
import ModifierPreparation from "../../pages/ModifierPreparation";
import ResetPassword from "../../pages/ResetPassword";

const index = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Accueil} />
        <Route path="/accueil" exact component={Connection} />
        <Route path="/inscription" exact component={Inscription} />
        <Route path="/ajoutproduit" exact component={AjoutProduit} />
        <Route path="/modifierproduit" exact component={ModifierProduit} />
        <Route
          path="/modifierpreparation"
          exact
          component={ModifierPreparation}
        />
        <Route path="/listeproduits" exact component={ListeProduits} />
        <Route path="/ajoutpreparation" exact component={AjouterPreparation} />
        <Route path="/listepreparations" exact component={ListePreparations} />
        <Route path="/carte" exact component={Carte} />
        <Route path="/compteperso" exact component={ComptePerso} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <Route path="/reset/:token/:id" exact component={ResetPassword} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default index;
