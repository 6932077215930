import { GET_CATEGORIES_PRODUCTS } from "../actions/categoriesProducts.actions";

const initialState = {};

export default function categoriesProductsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_CATEGORIES_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
