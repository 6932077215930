import { React, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Inscription = () => {
  const history = useHistory();

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");

  const handleInscription = async () => {
    if (
      nom &&
      prenom &&
      telephone &&
      email &&
      identifiant &&
      password &&
      rpassword
    ) {
      if (password === rpassword) {
        await axios({
          method: "post",
          url: process.env.REACT_APP_SERVER_URL + `/api/user/register/dist`,
          withCredentials: true,
          data: {
            nom,
            prenom,
            identifiant,
            telephone,
            email,
            password,
          },
        })
          .then((res) => {
            if (res.data.errors) {
              alert(res.data.errors);
            } else {
              alert("Votre compte a bien été créé");
              history.push("/accueil");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("Les deux mots de passe ne correspondent pas");
      }
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  return (
    <div className="info">
      <div>
        <h3>Informations personelles</h3>
        <ul>
          <li>
            <label>Nom</label>
            <input
              required
              type="text"
              onChange={(e) => setNom(e.target.value)}
            />
          </li>
          <li>
            <label>Prénom</label>
            <input
              required
              type="text"
              onChange={(e) => setPrenom(e.target.value)}
            />
          </li>
          <li>
            <label>Téléphone</label>
            <input
              required
              type="text"
              onChange={(e) => setTelephone(e.target.value)}
            />
          </li>
          <li>
            <label>Mail</label>
            <input
              required
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </li>
        </ul>
      </div>
      <div>
        <h3>Informations de connexion</h3>
        <ul>
          <li>
            <label>Identifiant</label>
            <input
              required
              type="text"
              onChange={(e) => setIdentifiant(e.target.value)}
            />
          </li>
          <li>
            <label>Mot de passe</label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </li>
          <li>
            <label>Confirmation du mot de passe</label>
            <input
              required
              type="password"
              onChange={(e) => setRpassword(e.target.value)}
            />
          </li>
          <button onClick={handleInscription}>VALIDER</button>
        </ul>
      </div>
    </div>
  );
};

export default Inscription;
