import axios from "axios";

export const GET_ENTREPRISES = "GET_ENTREPRISES";
export const GET_ENSEIGNES = "GET_ENSEIGNES";

export const getAllEntreprises = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/entreprise/get/all`)
      .then((res) => {
        dispatch({ type: GET_ENTREPRISES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getOneEntreprise = (idProducteur) => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/entreprise/get/one/${idProducteur}`)
      .then((res) => {
        dispatch({ type: GET_ENTREPRISES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getAllEnseignes = () => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/enseigne/get/all`)
      .then((res) => {
        dispatch({ type: GET_ENSEIGNES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getOneEnseigne = (idEnseigne) => {
  return async (dispatch) => {
    return await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/enseigne/get/one/${idEnseigne}`)
      .then((res) => {
        dispatch({ type: GET_ENSEIGNES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
